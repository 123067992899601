.LetsTalkSmall__container {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  touch-action: none;
  -ms-touch-action: none;
  overflow: hidden;
}

.LetsTalkSmall__wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  touch-action: none;
  -ms-touch-action: none;
  overflow: hidden;
  width: 100%;
}

.LetsTalkSmall__Header_contaner {
  height: 15.5em;
  display: flex;
  align-items: flex-end;
}

.LetsTalkSmall__maincontent_wrapper {
  margin-top: 0.8em;
  height: calc(100% - 11.5em);
  overflow: hidden;
}

.LetsTalkSmall__maincontent {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.LetsTalkSmall__form__container {
  margin-top: 3em;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.LetsTalkSmall__floatNumbers {
  position: relative;
  transform: rotate(270deg);
  width: 4%;
}

.LetsTalkSmall__floatNumbers p {
  font-family: "Oxygen Mono";
  font-size: 6px;
  letter-spacing: 0.5em;
  white-space: nowrap;
  position: absolute;
  left: -42em;
  top: -1em;
  margin: 0;
}

.LetsTalkSmall__form {
  width: 96%;
  height: 100%;
}

.LetsTalkSmall__sweeper__container,
.LetsTalkSmall__info__container {
  margin-top: 1em;
  width: 99%;
}

.LetsTalkSmall__info__container {
  margin-bottom: 1em;
}

/* WIDTH MEDIA QUERIES */

@media only screen and (max-width: 545px) {
  .LetsTalkSmall__Header_contaner {
    height: 16.3em;
  }
}

@media only screen and (max-width: 440px) {
  .LetsTalkSmall__Header_contaner {
    min-height: 195px;
  }
}
