.DesignBoxSmall__container {
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: right;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.DesignBoxSmall__container:hover {
  cursor: url("../../assets/images/arrowDown.svg"), auto;
}

::-webkit-scrollbar {
  display: none;
}

.DesignBoxSmall__wrapper {
  width: 100%;
  height: 99%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 0.2em;
  border-bottom: 1px black solid;
  overflow-y: scroll;
}

.DesignBoxSmall__item__container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 98%;
  min-height: 50%;
}

.DesignBoxSmall__item__wrapper {
  width: 100%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.DesignBoxSmall__item__container:nth-child(1) {
  border-bottom: 1px solid black;
}
.DesignBoxSmall__item__container:nth-child(2) {
  border-bottom: 1px solid black;
}
.DesignBoxSmall__item__container:nth-child(3) {
  border-bottom: 1px solid black;
}
.DesignBoxSmall__item__container:nth-child(4) {
  border-bottom: 1px solid black;
}

.DesignBoxSmall__item__left {
  height: 100%;
  width: 60%;
}

.DesignBoxSmall__item__left > p {
  font-size: 0.8em;
  font-family: "Oxygen Mono";
  margin: 8px 0 0 0;
}

.DesignBoxSmall__item_head {
  display: flex;
}

.DesignBoxSmall__item_head p {
  font-size: 1em;
  text-transform: uppercase;
  font-family: "Aux Mono";
  margin: 0;
}

.DesignBoxSmall__item_head img {
  margin-right: 10px;
}

.DesignBoxSmall__item__right {
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.DesignBoxSmall__item__right img {
  width: 70%;
  height: 70%;
}

@media only screen and (max-width: 541px) {
  .DesignBoxSmall__item__left > p {
    font-size: 0.7em;
  }
  .DesignBoxSmall__item_head p {
    font-size: 0.8em;
  }

  .DesignBox__item_head_table p {
    font-size: 11px;
  }
  .DesignBox__table table td {
    font-size: 10px;
    height: 2em;
  }
}

@media only screen and (max-width: 345px) {
  .DesignBoxSmall__item__left > p {
    font-size: 10px;
  }
  .DesignBoxSmall__item_head p {
    font-size: 9px;
  }

  .DesignBoxSmall__item__right img {
    width: 70%;
    height: 70%;
  }
}
