.FormBooking__container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.FormBooking__wrapper {
  border: 1px solid black;
  height: calc(100% - 117px);
  margin-bottom: 1em;
  width: 100%;
  background: #ededed;
  z-index: 1;
  position: relative;
}

.FormBooking__buttons_container {
  width: 90%;
  display: flex;
  position: relative;
  top: -32px;
  align-items: center;
  justify-content: space-between;
  margin-left: 2em;
}

.FormBooking__button_active {
  border: 1px solid black;
  position: relative;
  border-bottom: 0;
  height: 30px;
  border-radius: 10px 10px 0 0;
  padding: 0.1em 1em 0em 1em;
  background: #ededed;
  z-index: 2;
  font-family: "Cygnito Mono";
  color: #404040;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

.FormBooking__form_container {
  width: 100%;
  height: 100%;
  height: calc(70% - 1em);
  margin-bottom: 1em;
}

.FormBooking__form_container form {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.FormBooking__form_wrapper {
  width: 90%;
  height: 100%;
  margin: 0em 1em 0.8em 1em;
  display: flex;
  justify-content: space-between;
}

.FormBooking__form_upload {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  width: calc(40% + 1em);
  height: 20px;
  font-size: 10px;
}

.FormBooking__form_upload p {
  margin: 0 1em 0 0;
  font-family: "Cygnito Mono";
  /* font-size: 0.8em; */
  letter-spacing: 2px;
  /* word-spacing: 10px; */
  line-height: 1;
  /* white-space: nowrap; */
}

.FormBooking__form_upload input[type="file"] {
  position: absolute;
  width: 20%;
  opacity: 0;
}

.FormBooking__form_references {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  overflow-x: scroll;
}

.FormBooking__form_reference {
  margin-right: 20px;
}

.FormBooking__form_title {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-y: hidden;
  font-family: "Cygnito Mono";
  margin: 0 1em 0 0;
  font-family: "Cygnito Mono";
  /* font-size: 0.8em; */
  letter-spacing: 2px;
  /* word-spacing: 10px; */
  line-height: 1;
}

.FormBooking__form_reference span {
  position: absolute;
  right: 6px;
}

.FormBooking__form_wrapper_area {
  width: 100%;
  height: 60px;
  border: 1.39483px solid #8e8e8e;
  box-shadow: inset 2.09225px -2.78967px 1.39483px #e9e9e9,
    inset 2.09225px 2.09225px 1.39483px #000000;
  outline: none;
  padding: 10px 0 0 0.5em;
}

.FormBooking__form_button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.FormBooking__form_button > button {
  border-left: 2px solid black;
  border-right: 4px solid black;
  border-top: 2px solid black;
  border-bottom: 3px solid black;
  width: 20%;
  font-family: "Cygnito Mono";
  box-shadow: inset 2.09225px -2.78967px 1.39483px gray,
    inset 2.09225px 2.09225px 1.39483px #000;
}

/* HEIGHT MEDIA QUERIES */

@media only screen and (max-height: 885px) {
  .FormBooking__form_container form {
    margin: -0.8em 0 0 0;
  }
}

@media only screen and (max-height: 830px) {
  .FormBooking__wrapper {
    height: calc(100% - 115px);
    margin-bottom: 0.8em;
  }

  .FormBooking__form_container {
    height: calc(100% - 10px);
    margin-bottom: 1em;
  }

  .FormBooking__buttons_container {
    top: -25px;
  }

  .FormBooking__button_active {
    height: 20px;
    font-size: 13px;
  }
}

@media only screen and (max-height: 780px) {
  .FormBooking__wrapper {
    height: calc(100% - 110px);
    margin-bottom: 0.5em;
  }

  .FormBooking__form_container {
    height: calc(100% - 0);
    margin-bottom: 10em;
  }

  .FormBooking__buttons_container {
    top: -25px;
  }

  .FormBooking__button_active {
    height: 20px;
    font-size: 13px;
  }
  .FormBooking__form_wrapper_area {
    height: 45px;
  }
  .FormBooking__form_container form {
    margin: -1em 0 0 0;
  }

  .FormBooking__form_wrapper {
    width: 90%;
    height: auto;
    margin: 0em 1em 0.5em 1em;
    display: flex;
    justify-content: space-between;
  }
}

@media only screen and (max-height: 730px) {
  .FormBooking__wrapper {
    height: calc(100% - 90px);
    margin-bottom: 0.5em;
  }

  .FormBooking__form_container {
    height: calc(100% - 0);
    margin-bottom: 10em;
  }

  .FormBooking__form_button > button {
    border-left: 1px solid black;
    border-right: 2px solid black;
    border-top: 1px solid black;
    border-bottom: 1.5px solid black;
    font-size: 12px;
    height: 25px;
  }

  .FormBooking__form_wrapper_area {
    height: 30px;
  }
}

/* WIDTH MEDIA QUERIES */

@media only screen and (max-width: 1837px) {
  .FormBooking__form_upload p {
    font-size: 0.9em;
  }
}

@media only screen and (max-width: 1720px) {
  .FormBooking__form_upload p {
    font-size: 0.8em;
  }
}

@media only screen and (max-width: 1630px) {
  .FormBooking__form_upload p {
    font-size: 0.7em;
  }
}

@media only screen and (max-width: 1475px) {
  .FormBooking__form_upload p {
    font-size: 0.6em;
  }

  .FormBooking__form_upload img {
    width: 30px;
  }
}

@media only screen and (max-width: 1305px) {
  .FormBooking__form_upload img {
    width: 25px;
  }
}

@media only screen and (max-width: 1270px) {
  .FormBooking__form_upload img {
    width: 23px;
  }
  .FormBooking__form_upload p {
    font-size: 0.55em;
  }
}

.FormBooking__error {
  font-family: "Oxygen Mono";
  font-size: 11px;
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.8);
}

@media only screen and (max-width: 480px) {
  .FormBooking__error {
    font-family: "Oxygen Mono";
    font-size: 8px;
    margin-right: 10px;
  }

  .FormBooking__form_button > button {
    width: 65px;
  }
}

@media only screen and (min-width: 768px) {
  .FormBooking__form_upload {
    font-family: "Oxygen Mono";
    font-size: 14px;
    height: 30px;
  }
}
