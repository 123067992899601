.GallerySmall__container {
  width: 80%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  touch-action: none;
  -ms-touch-action: none;
}

.GallerySmall__wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
  touch-action: none;
  -ms-touch-action: none;
}

.GallerySmall__Header_container {
  height: 15.5em;
  margin-top: 0em;
  display: flex;
  align-items: flex-end;
}

.GallerySmall__maincontent_wrapper {
  margin-top: 0.8em;
  height: calc(100% - 11.5em);
  overflow: hidden;
  width: 100%;
}

.GallerySmall__maincontent {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.GallerySmall__box_text {
  width: 100%;
}

.GallerySmall__box_text p {
  font-family: "Oxygen Mono";
  text-align: justify;
}

.GallerySmall__subtitle {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
}

.GallerySmall__subtitle h3 {
  margin: 2.3em 0 1em 0;
  font-family: "Neue Montreal light";
  font-size: 1.2em;
  font-weight: lighter;
  letter-spacing: 3px;
  word-spacing: 4px;
}

.GallerySmall__subtitle span {
  background-color: black;
  color: white;
  font-family: "Neue Montreal";
}

/* WIDTH MEDIA QUERIES */

@media only screen and (max-width: 545px) {
  .GallerySmall__Header_container {
    height: 16.3em;
  }
  .GallerySmall__box_text p {
    font-size: 0.7em;
  }

  .GallerySmall__subtitle h3 {
    margin: 2.3em 0 1em 0;
    font-family: "Neue Montreal light";
    font-size: 0.9em;
    font-weight: lighter;
    letter-spacing: 3px;
    word-spacing: 4px;
  }
}

@media only screen and (max-width: 440px) {
  .GallerySmall__Header_container {
    min-height: 195px;
  }
}
