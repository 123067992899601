.GridAboutSmall__container {
  border: 1px solid black;
  width: 100%;
  height: 100%;
  display: flex;
}

.GridAboutSmall__left_container,
.GridAboutSmall__right_container {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.GridAboutSmall__right_container {
  border-left: 1px solid black;
}

.GridAboutSmall__item {
  height: calc(100% / 3);
  width: 100%;
  border-bottom: 1px solid black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.GridAboutSmall__item:last-child {
  border-bottom: 0;
}

.GridAbout__item_dark {
  height: calc(100% / 3);
  width: 100%;
  border-bottom: 1px solid white;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.GridAbout__item_dark:last-child {
  border-bottom: 0;
}

.GridAboutSmall__item_text {
  height: 80%;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.GridAboutSmall__item_text span {
  font-family: "Aux Mono";
  text-align: justify;
  font-size: 1.5em;
}

.GridAboutSmall__item_text p {
  font-family: "Oxygen Mono";
  text-align: justify;
  font-size: 1em;
  margin: 0;
}

.GridAboutSmall__item_text_dark {
  height: 80%;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.GridAboutSmall__item_text_dark span {
  font-family: "Aux Mono";
  text-align: justify;
  font-size: 1.5em;
  color: white;
}

.GridAboutSmall__item_text_dark p {
  font-family: "Oxygen Mono";
  text-align: justify;
  font-size: 1em;
  margin: 0;
  color: white;
}

.GridAboutSmall__item_link {
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.GridAboutSmall__item_link a {
  text-decoration: none;
  color: black;
  margin-right: 0.5em;
  font-size: 0.65em;
  font-family: "Oxygen Mono";
}

.GridAboutSmall__item_link img {
  margin-right: 1em;
}

.GridAboutSmall__item_link_dark {
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.GridAboutSmall__item_link_dark a {
  text-decoration: none;
  color: white;
  margin-right: 0.5em;
  font-size: 0.65em;
  font-family: "Oxygen Mono";
}

.GridAboutSmall__item_link_dark img {
  margin-right: 1em;
  filter: invert(100%);
}

.GridAboutSmall__subitem_top {
  height: 60%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid black;
}

.GridAboutSmall__subitem_top img {
  width: 290px;
}

.GridAboutSmall__subitem_top_dark {
  height: 60%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid white;
}

.GridAboutSmall__subitem_top_dark img {
  width: 290px;
  filter: invert(100%);
}

.GridAboutSmall__subitem_bottom {
  height: 40%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.GridAboutSmall__subitem_bottom_left {
  width: 30%;
  height: 100%;
  border-right: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.GridAboutSmall__subitem_bottom_left img {
  width: 45px;
}

.GridAboutSmall__subitem_bottom_left_dark {
  width: 30%;
  height: 100%;
  border-right: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.GridAboutSmall__subitem_bottom_left_dark img {
  width: 45px;
  filter: invert(100%);
}

.GridAboutSmall__subitem_bottom_right {
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.GridAboutSmall__subitem_bottom_right p {
  margin: 0;
  font-family: "Oxygen Mono";
  font-size: 1.4em;
  font-weight: 500;
  letter-spacing: 2px;
}

.GridAboutSmall__subitem_bottom_right_dark {
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.GridAboutSmall__subitem_bottom_right_dark p {
  margin: 0;
  font-family: "Oxygen Mono";
  font-size: 1.4em;
  font-weight: 500;
  letter-spacing: 2px;
  color: white;
}

@media only screen and (max-width: 890px) {
  .GridAboutSmall__subitem_top img,
  .GridAboutSmall__subitem_top_dark img {
    width: 275px;
  }
}

@media only screen and (max-width: 840px) {
  .GridAboutSmall__subitem_top img,
  .GridAboutSmall__subitem_top_dark img {
    width: 260px;
  }

  .GridAboutSmall__subitem_bottom_left img,
  .GridAboutSmall__subitem_bottom_left_dark img {
    width: 38px;
  }
}

@media only screen and (max-width: 800px) {
  .GridAboutSmall__item_text p,
  .GridAboutSmall__item_text_dark p {
    font-size: 0.9em;
  }
}

@media only screen and (max-width: 790px) {
  .GridAboutSmall__subitem_top img,
  .GridAboutSmall__subitem_top_dark img {
    width: 240px;
  }
}

@media only screen and (max-width: 730px) {
  .GridAboutSmall__subitem_top img,
  .GridAboutSmall__subitem_top_dark img {
    width: 220px;
  }

  .GridAboutSmall__subitem_bottom_left img,
  .GridAboutSmall__subitem_bottom_left_dark img {
    width: 35px;
  }

  .GridAboutSmall__subitem_bottom_right p,
  .GridAboutSmall__subitem_bottom_right_dark p {
    font-size: 1.2em;
  }
}

@media only screen and (max-width: 680px) {
  .GridAboutSmall__subitem_top img,
  .GridAboutSmall__subitem_top_dark img {
    width: 205px;
  }

  .GridAboutSmall__subitem_bottom_left img,
  .GridAboutSmall__subitem_bottom_left_dark img {
    width: 33px;
  }

  .GridAboutSmall__subitem_bottom_right p,
  .GridAboutSmall__subitem_bottom_right_dark p {
    font-size: 1em;
  }
}

@media only screen and (max-width: 646px) {
  .GridAboutSmall__item_text p,
  .GridAboutSmall__item_text_dark p {
    font-size: 0.8em;
  }
  .GridAboutSmall__item_link a,
  .GridAboutSmall__item_link_dark a {
    font-size: 0.6em;
  }
  .GridAboutSmall__subitem_top img,
  .GridAboutSmall__subitem_top_dark img {
    width: 200px;
  }
}

@media only screen and (max-width: 600px) {
  .GridAboutSmall__subitem_top img,
  .GridAboutSmall__subitem_top_dark img {
    width: 185px;
  }

  .GridAboutSmall__subitem_bottom_left img,
  .GridAboutSmall__subitem_bottom_left_dark img {
    width: 30px;
  }

  .GridAboutSmall__subitem_bottom_right p,
  .GridAboutSmall__subitem_bottom_right_dark p {
    font-size: 0.9em;
  }

  .GridAboutSmall__item_link img,
  .GridAboutSmall__item_link_dark img {
    width: 50px;
  }
}

@media only screen and (max-width: 574px) {
  .GridAboutSmall__item_text p,
  .GridAboutSmall__item_text_dark p {
    font-size: 0.7em;
  }
  .GridAboutSmall__item_link a,
  .GridAboutSmall__item_link_dark a {
    font-size: 0.55em;
  }
}

@media only screen and (max-width: 560px) {
  .GridAboutSmall__subitem_top img,
  .GridAboutSmall__subitem_top_dark img {
    width: 175px;
  }

  .GridAboutSmall__subitem_bottom_left img,
  .GridAboutSmall__subitem_bottom_left_dark img {
    width: 28px;
  }
}

@media only screen and (max-width: 530px) {
  .GridAboutSmall__subitem_top img,
  .GridAboutSmall__subitem_top_dark img {
    width: 165px;
  }
}

@media only screen and (max-width: 503px) {
  .GridAboutSmall__item_link a,
  .GridAboutSmall__item_link_dark a {
    font-size: 0.5em;
  }
}

@media only screen and (max-width: 500px) {
  .GridAboutSmall__subitem_top img,
  .GridAboutSmall__subitem_top_dark img {
    width: 155px;
  }

  .GridAboutSmall__subitem_bottom_left img,
  .GridAboutSmall__subitem_bottom_left_dark img {
    width: 26px;
  }

  .GridAboutSmall__subitem_bottom_right p,
  .GridAboutSmall__subitem_bottom_right_dark p {
    font-size: 0.75em;
  }
}

@media only screen and (max-width: 480px) {
  .GridAboutSmall__subitem_top img,
  .GridAboutSmall__subitem_top_dark img {
    width: 150px;
  }
}

@media only screen and (max-width: 468px) {
  .GridAboutSmall__subitem_top img,
  .GridAboutSmall__subitem_top_dark img {
    width: 145px;
  }
  .GridAboutSmall__subitem_bottom_right p,
  .GridAboutSmall__subitem_bottom_right_dark p {
    font-size: 0.7em;
  }
  .GridAboutSmall__subitem_bottom_left img,
  .GridAboutSmall__subitem_bottom_left_dark img {
    width: 24px;
  }
  .GridAboutSmall__item_text p,
  .GridAboutSmall__item_text_dark p {
    font-size: 0.65em;
  }
}

@media only screen and (max-width: 440px) {
  .GridAboutSmall__subitem_top img,
  .GridAboutSmall__subitem_top_dark img {
    width: 135px;
  }
  .GridAboutSmall__subitem_bottom_right p,
  .GridAboutSmall__subitem_bottom_right_dark p {
    font-size: 0.6em;
  }
}

@media only screen and (max-width: 420px) {
  .GridAboutSmall__subitem_top img,
  .GridAboutSmall__subitem_top_dark img {
    width: 130px;
  }
  .GridAboutSmall__subitem_bottom_right p,
  .GridAboutSmall__subitem_bottom_right_dark p {
    font-size: 0.6em;
  }

  .GridAboutSmall__subitem_bottom_left img,
  .GridAboutSmall__subitem_bottom_left_dark img {
    width: 22px;
  }
}

@media only screen and (max-width: 400px) {
  .GridAboutSmall__subitem_top img,
  .GridAboutSmall__subitem_top_dark img {
    width: 120px;
  }

  .GridAboutSmall__item_link img,
  .GridAboutSmall__item_link_dark img {
    width: 40px;
  }
}

@media only screen and (max-width: 370px) {
  .GridAboutSmall__item_text p,
  .GridAboutSmall__item_text_dark p {
    font-size: 0.6em;
  }
}

@media only screen and (max-width: 360px) {
  .GridAboutSmall__subitem_top img,
  .GridAboutSmall__subitem_top_dark img {
    width: 110px;
  }

  .GridAboutSmall__subitem_bottom_left img,
  .GridAboutSmall__subitem_bottom_left_dark img {
    width: 20px;
  }

  .GridAboutSmall__subitem_bottom_right p,
  .GridAboutSmall__subitem_bottom_right_dark p {
    font-size: 0.5em;
  }
}
