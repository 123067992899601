.HomeSmall_3__container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url("../../assets/images/statuette.png");
  background-position: 72% bottom;
  background-repeat: no-repeat;
  padding-bottom: 3%;
  background-size: 550px;
}

.HomeSmall_3__wrapper {
  margin: 0px;
  padding: 0px;
  /* justify-content: space-between; */
  width: 700px;
  padding-bottom: 8%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 1;
}

.HomeSmall_3__box {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.HomeSmall_3__phrase {
  font-family: "Neue Montreal medium";
  font-weight: bold;
  font-size: 50px;
  letter-spacing: 7px;
  position: relative;
}

.HomeSmall_3__phrase img {
  position: absolute;
  width: 255px;
  left: -9px;
  top: 5px;
}

.HomeSmall_3__phrase_small {
  font-family: "Oxygen Mono";
  font-weight: lighter;
  font-size: 15px;
  color: #000000;
  letter-spacing: 0px;
  word-spacing: 3px;
  margin-top: 1.5em;
}

@media only screen and (max-width: 850px) {
  .HomeSmall_3__phrase {
    font-size: 40px;
  }

  .HomeSmall_3__phrase img {
    width: 210px;
    left: -6px;
    top: 3px;
  }

  .HomeSmall_3__phrase_small {
    font-size: 12px;
  }

  .HomeSmall_3__wrapper {
    width: 600px;
  }
  .HomeSmall_3__container {
    background-size: 400px;
  }
}

@media only screen and (max-width: 650px) {
  .HomeSmall_3__phrase {
    font-size: 30px;
  }

  .HomeSmall_3__phrase img {
    width: 170px;
    left: -5px;
    top: 1px;
  }

  .HomeSmall_3__phrase_small {
    font-size: 12px;
  }

  .HomeSmall_3__wrapper {
    width: 550px;
  }

  .HomeSmall_3__container {
    background-size: 350px;
  }
}

@media only screen and (max-width: 600px) {
  .HomeSmall_3__phrase {
    font-size: 25px;
  }

  .HomeSmall_3__phrase img {
    width: 150px;
    left: -6px;
    top: 0px;
  }

  .HomeSmall_3__phrase_small {
    font-size: 12px;
  }

  .HomeSmall_3__wrapper {
    width: 500px;
  }
}

@media only screen and (max-width: 550px) {
  .HomeSmall_3__wrapper {
    width: 450px;
  }

  .HomeSmall_3__container {
    background-size: 300px;
  }
}

@media only screen and (max-width: 500px) {
  .HomeSmall_3__wrapper {
    width: 400px;
  }
}

@media only screen and (max-width: 450px) {
  .HomeSmall_3__phrase {
    font-size: 20px;
  }
  .HomeSmall_3__phrase img {
    width: 140px;
    left: -5px;
    top: -2px;
    bottom: 0px;
  }
  .HomeSmall_3__wrapper {
    width: 350px;
  }
}

@media only screen and (max-width: 400px) {
  .HomeSmall_3__wrapper {
    width: 325px;
  }

  .HomeSmall_3__phrase {
    font-size: 19px;
    letter-spacing: 5px;
  }

  .HomeSmall_3__phrase img {
    width: 125px;
    left: -5px;
  }

  .HomeSmall_3__phrase_small {
    font-size: 11px;
  }
}

@media only screen and (max-width: 350px) {
  .HomeSmall_3__wrapper {
    width: 300px;
  }

  .HomeSmall_3__phrase_small {
    font-size: 11px;
  }
}
