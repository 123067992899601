.container__lamp_vertical {
  border: 1px solid black;
  border-radius: 2px;
  height: 100%;
  width: 3px;
  position: relative;
}

.dot__wrapper_vertical {
  position: absolute;
  top: 2px;
  list-style: none;
}

.dot__wrapper_vertical li {
  position: absolute;
  height: 10px;
  width: 4px;
  padding: 0;
  margin: 0;
  left: 0px;
  right: 0px;
  background-color: black;
}
