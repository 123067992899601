.FilterLock__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border: 3px dashed black;
  background-color: rgba(255, 255, 255, 0.6);
  opacity: 1;
  height: 100%;
  width: 99%;
  position: absolute;
}

.FilterLock__container img {
  height: 200px;
  width: 300px;
  position: relative;
  left: 35%;
}

@media only screen and (max-width: 1799px) {
  .FilterLock__container img {
    height: 200px;
    width: 300px;
    left: 32%;
  }
}

@media only screen and (max-width: 1599px) {
  .FilterLock__container img {
    height: 200px;
    width: 300px;
    left: 28%;
  }
}

@media only screen and (max-width: 1399px) {
  .FilterLock__container img {
    height: 200px;
    width: 200px;
    left: 32%;
  }
}

@media only screen and (max-width: 1099px) {
  .FilterLock__container img {
    height: 200px;
    width: 200px;
    left: 28%;
  }
}

@media only screen and (max-width: 950px) {
  .FilterLock__container img {
    height: 200px;
    width: 300px;
    left: 34%;
  }
}

@media only screen and (max-width: 720px) {
  .FilterLock__container img {
    left: 32%;
  }
}

@media only screen and (max-width: 640px) {
  .FilterLock__container img {
    left: 28%;
  }
}

@media only screen and (max-width: 550px) {
  .FilterLock__container img {
    left: 20%;
  }
}

@media only screen and (max-width: 480px) {
  .FilterLock__container img {
    width: 200px;
    height: 200px;
    left: 30%;
  }
}

@media only screen and (max-width: 430px) {
  .FilterLock__container img {
    width: 200px;
    height: 200px;
    left: 25%;
  }
}

@media only screen and (max-width: 380px) {
  .FilterLock__container img {
    width: 200px;
    height: 200px;
    left: 20%;
  }
}
