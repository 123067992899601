.HomeSmall_4__container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url("../../assets/images/hand.png");
  background-position: 65% bottom;
  background-repeat: no-repeat;
  padding-bottom: 3%;
  background-size: 500px;
}

.HomeSmall_4__wrapper {
  margin: 0px;
  padding: 0px;
  /* justify-content: space-between; */
  width: 700px;
  padding-bottom: 8%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 1;
}

.HomeSmall_4__box {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.HomeSmall_4__phrase {
  font-family: "Neue Montreal medium";
  font-weight: bold;
  font-size: 50px;
  letter-spacing: 7px;
  position: relative;
}

.HomeSmall_4__phrase img {
  position: absolute;
  width: 300px;
  left: -7px;
}

@media only screen and (max-width: 850px) {
  .HomeSmall_4__phrase {
    font-size: 40px;
  }

  .HomeSmall_4__phrase img {
    position: absolute;
    width: 250px;
    left: -7px;
  }

  .HomeSmall_4__wrapper {
    width: 600px;
  }

  .HomeSmall_4__container {
    background-size: 450px;
  }
}

@media only screen and (max-width: 650px) {
  .HomeSmall_4__phrase {
    font-size: 30px;
  }

  .HomeSmall_4__phrase img {
    width: 200px;
    left: -7px;
    top: -3px;
  }

  .HomeSmall_4__wrapper {
    width: 550px;
  }
  .HomeSmall_4__container {
    background-size: 400px;
  }
}

@media only screen and (max-width: 600px) {
  .HomeSmall_4__phrase {
    font-size: 25px;
  }

  .HomeSmall_4__phrase img {
    width: 175px;
    left: -5px;
    top: -3px;
  }

  .HomeSmall_4__wrapper {
    width: 500px;
  }
}

@media only screen and (max-width: 550px) {
  .HomeSmall_4__wrapper {
    width: 450px;
  }

  .HomeSmall_4__container {
    background-size: 350px;
  }
}

@media only screen and (max-width: 500px) {
  .HomeSmall_4__wrapper {
    width: 400px;
  }
}

@media only screen and (max-width: 450px) {
  .HomeSmall_4__phrase {
    font-size: 20px;
  }
  .HomeSmall_4__wrapper {
    width: 350px;
  }

  .HomeSmall_4__phrase img {
    width: 154px;
    left: -5px;
    top: -5px;
    bottom: 0;
  }
}

@media only screen and (max-width: 400px) {
  .HomeSmall_4__wrapper {
    width: 325px;
  }

  .HomeSmall_4__phrase {
    font-size: 19px;
    letter-spacing: 5px;
  }

  .HomeSmall_4__phrase img {
    width: 142px;
    left: -5px;
    top: -2px;
  }
}

@media only screen and (max-width: 350px) {
  .HomeSmall_4__wrapper {
    width: 300px;
  }
}
