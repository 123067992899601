.Appointment__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 80%;
}

.Appointment__wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.Appointment__header_container {
  height: 260px;
  display: flex;
  align-items: flex-end;
}

.Appointment__content_wrapper {
  height: calc(100% - 260px);
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

.Appointment__content_wrapper > img {
  width: 300px;
  margin: 1em 0 1em 2em;
}

.Appointment__content_wrapper > p {
  font-family: "Aux Mono";
  font-size: 32px;
  margin: 1em 0 0.4em 0.7em;
}

.Appointment__content_container {
  width: 100%;
  max-height: 585px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Appointment__left_container {
  width: 48%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.Appointment__right_container {
  width: 48%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.Appointment__left_wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.Appointment__left_date {
  transform: rotate(270deg);
  position: relative;
  top: 20em;
  left: 0;
  width: 5%;
  font-family: "Oxygen Mono";
  white-space: nowrap;
}

.Appointment__left_form {
  height: 470px;
  width: 85%;
  margin-top: 1.7em;
}

.Appointment__left_infodate_container {
  position: relative;
  width: 10%;
  height: 510px;
}

.Appointment__left_infodate {
  transform: rotate(270deg);
  top: 51px;
  bottom: 0px;
  left: 1em;
  width: 439px;
  position: absolute;
}

.Appointment__right_wrapper {
  height: 500px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

/* HEIGHT MEDIA QUERIES  */

@media only screen and (max-height: 920px) {
  .Appointment__header_container {
    height: 235px;
  }

  .Appointment__content_wrapper {
    height: calc(100% - 235px);
  }
}

@media only screen and (max-height: 900px) {
  .Appointment__header_container {
    height: 205px;
  }

  .Appointment__content_wrapper {
    height: calc(100% - 205px);
  }

  .Appointment__content_wrapper > p {
    font-size: 28px;
  }
}

@media only screen and (max-height: 830px) {
  .Appointment__header_container {
    height: 205px;
  }

  .Appointment__content_wrapper {
    height: calc(100% - 205px);
    justify-content: flex-start;
  }
  .Appointment__left_form {
    height: 418px;
  }

  .Appointment__right_wrapper {
    height: 465px;
  }
  .Appointment__content_wrapper > p {
    font-size: 25px;
  }
  .Appointment__left_date {
    font-size: 13px;
  }

  .Appointment__left_infodate {
    top: 15px;
    bottom: 0;
    left: 4em;
    width: 404px;
  }
}

@media only screen and (max-height: 780px) {
  .Appointment__content_wrapper > p {
    font-size: 20px;
    margin: 1em 0 0.5em 1.2em;
  }
  .Appointment__left_infodate {
    top: 0;
    bottom: 22px;
    left: 5em;
    width: 364px;
  }
  .Appointment__right_wrapper {
    height: 425px;
  }
  .Appointment__left_form {
    height: 399px;
  }
  .Appointment__left_date {
    font-size: 10px;
  }
}

@media only screen and (max-height: 730px) {
  .Appointment__header_container {
    height: 180px;
  }

  .Appointment__content_wrapper {
    height: calc(100% - 180px);
  }

  .Appointment__left_infodate {
    top: 0;
    bottom: 74px;
    left: 5em;
    width: 315px;
  }

  .Appointment__left_form {
    height: 347px;
  }

  .Appointment__right_wrapper {
    height: 370px;
  }

  .Appointment__content_wrapper > p {
    margin: 1em 0 0.5em 1.2em;
  }
}

@media only screen and (max-height: 650px) {
  .Appointment__header_container {
    height: 180px;
  }

  .Appointment__content_wrapper {
    height: calc(100% - 180px);
  }

  .Appointment__left_infodate {
    top: 8.7em;
    left: -5em;
    width: 16.7em;
  }

  .Appointment__left_form {
    height: 301px;
    margin-top: 0.5em;
  }

  .Appointment__right_wrapper {
    height: 300px;
  }

  .Appointment__left_infodate {
    top: 0;
    bottom: 158px;
    left: 4em;
    width: 266px;
  }

  .Appointment__content_wrapper > p {
    font-size: 16px;
    margin: 1em 0 1.5em 1.5em;
  }
  .Appointment__left_date {
    font-size: 9px;
  }
}
