.Location {
  z-index: 1;
  width: 100%;
  height: 100%;
}

.main__bar_container {
  width: 100%;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  position: relative;
}

.main__bar {
  width: 99%;
  border: 1px solid black;
  z-index: 1;
  height: 5px;
}
.main__dots_container {
  width: 100%;
  display: flex;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  z-index: 1;
}

.main__dot {
  background-color: black;
  z-index: 1;
}

.main__dot_1 {
  height: 6px;
  width: 1.5em;
}

.main__dot_2 {
  height: 6px;
  width: 0.5em;
  margin-left: 3em;
}

.main__dot_3 {
  height: 6px;
  width: 0.5em;
  margin-left: 1em;
}

.main__dot_4 {
  height: 6px;
  width: 0.5em;
  margin-left: 1em;
}

.main__dot_5 {
  height: 6px;
  width: 0.5em;
  margin-left: 6em;
}

.main__dot_6 {
  height: 6px;
  width: 0.5em;
  margin-left: 6em;
}

.main__dot_7 {
  height: 6px;
  width: 0.5em;
  margin-left: 6em;
}

.main__dot_8 {
  height: 6px;
  width: 0.3em;
  margin-left: 6em;
}

.main__dot_9 {
  height: 6px;
  width: 0.3em;
  margin-left: 2em;
}

.main__location {
  margin-top: 10px;
  background: black;
  position: relative;
  width: 100%;
  margin: 0 auto;
  height: 120px;
  font-family: "Oxygen Mono";
  clip-path: polygon(0 0, 100% 0, 100% 50%, 100% 100%, 5% 100%, 0 90%);
}

.main__inside {
  position: absolute;
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
  background-color: #f3f3f3;
  clip-path: polygon(0 0, 100% 0, 100% 50%, 100% 100%, 5% 100%, 0 90%);
}

.main__location_info {
  height: 80px;
  display: flex;
  border-bottom: 1px solid black;
  font-size: 12px;
}

.main__location_icon {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
}

.main__location_marker {
  height: 45px;
  width: 30px;
}

.main__location_address {
  border-left: 1px solid black;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px;
  line-height: 20px;
  font-size: 11px;
  text-align: justify;
}

.main__location_adress p {
  text-align: justify;
  margin: 0;
  font-family: "Oxygen Mono";
}

.main__location_coordinates {
  height: 40px;
  font-size: 11px;
  letter-spacing: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main__location_coordinates p {
  font-family: "Oxygen Mono";
  font-size: 14px;
}

@media only screen and (max-height: 760px) {
  .main__location_marker {
    height: 30px;
    width: 20px;
  }

  .main__location_info {
    height: 50px !important;
  }

  .main__location {
    margin-top: 10px;
    background: black;
    position: relative;
    width: 100%;
    margin: 0 auto;
    height: 80px !important;
    font-family: "Oxygen Mono";
    clip-path: polygon(0 0, 100% 0, 100% 50%, 100% 100%, 5% 100%, 0 90%);
  }
  .main__location_coordinates {
    font-size: 11px;
    letter-spacing: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main__location_info {
    display: flex;
    border-bottom: 1px solid black;
    font-size: 12px;
  }
}

@media only screen and (max-width: 1830px) {
  .main__dot_1 {
    width: 1.5em;
  }

  .main__dot_2 {
    margin-left: 3em;
  }

  .main__dot_3 {
    margin-left: 1em;
  }

  .main__dot_4 {
    margin-left: 1em;
  }

  .main__dot_5 {
    margin-left: 5em;
  }

  .main__dot_6 {
    margin-left: 5em;
  }

  .main__dot_7 {
    margin-left: 5em;
  }

  .main__dot_8 {
    margin-left: 6em;
  }

  .main__dot_9 {
    margin-left: 2em;
  }
}

@media only screen and (max-width: 1630px) {
  .main__dot_1 {
    width: 1.5em;
  }

  .main__dot_2 {
    margin-left: 3em;
  }

  .main__dot_3 {
    margin-left: 1em;
  }

  .main__dot_4 {
    margin-left: 1em;
  }

  .main__dot_5 {
    margin-left: 4em;
  }

  .main__dot_6 {
    margin-left: 4em;
  }

  .main__dot_7 {
    margin-left: 4em;
  }

  .main__dot_8 {
    margin-left: 5em;
  }

  .main__dot_9 {
    margin-left: 3em;
  }
}

@media only screen and (max-width: 1520px) {
  .main__location {
    height: 110px;
  }

  .main__location_coordinates {
    height: 30px;
    letter-spacing: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .main__location_coordinates p {
    font-size: 12px;
  }
  .main__dot_1 {
    width: 1.5em;
  }

  .main__dot_2 {
    margin-left: 2em;
  }

  .main__dot_3 {
    margin-left: 1em;
  }

  .main__dot_4 {
    margin-left: 1em;
  }

  .main__dot_5 {
    margin-left: 4em;
  }

  .main__dot_6 {
    margin-left: 4em;
  }

  .main__dot_7 {
    margin-left: 4em;
  }

  .main__dot_8 {
    margin-left: 4em;
  }

  .main__dot_9 {
    margin-left: 3em;
  }
}

@media only screen and (max-width: 1450px) {
  .main__location {
    height: 100px;
  }

  .main__location_info {
    height: 65px;
    display: flex;
  }
  .main__dot_1 {
    width: 1.5em;
  }

  .main__dot_2 {
    margin-left: 2em;
  }

  .main__dot_3 {
    margin-left: 1em;
  }

  .main__dot_4 {
    margin-left: 1em;
  }

  .main__dot_5 {
    margin-left: 4em;
  }

  .main__dot_6 {
    margin-left: 4em;
  }

  .main__dot_7 {
    margin-left: 3em;
  }

  .main__dot_8 {
    margin-left: 3em;
  }

  .main__dot_9 {
    margin-left: 3em;
  }
}
@media only screen and (max-width: 1300px) {
  .main__dot_1 {
    width: 1.5em;
  }

  .main__dot_2 {
    margin-left: 2em;
  }

  .main__dot_3 {
    margin-left: 1em;
  }

  .main__dot_4 {
    margin-left: 1em;
  }

  .main__dot_5 {
    margin-left: 2.5em;
  }

  .main__dot_6 {
    margin-left: 2.5em;
  }

  .main__dot_7 {
    margin-left: 3em;
  }

  .main__dot_8 {
    margin-left: 3em;
  }

  .main__dot_9 {
    margin-left: 3em;
  }
}

@media only screen and (max-width: 1150px) {
  .main__location_coordinates p {
    font-size: 10px;
  }
  .main__dot_1 {
    width: 1.5em;
  }

  .main__dot_2 {
    margin-left: 1em;
  }

  .main__dot_3 {
    margin-left: 1em;
  }

  .main__dot_4 {
    margin-left: 1em;
  }

  .main__dot_5 {
    margin-left: 2.5em;
  }

  .main__dot_6 {
    margin-left: 2.5em;
  }

  .main__dot_7 {
    margin-left: 2.5em;
  }

  .main__dot_8 {
    margin-left: 2.5em;
  }

  .main__dot_9 {
    margin-left: 2.5em;
  }
}

@media only screen and (max-width: 1100px) {
  .main__location_address {
    padding: 20px;
    line-height: 18px;
    font-size: 10px;
  }
}

@media only screen and (max-width: 1125px) {
  .main__location_address {
    padding: 15px;
    font-size: 9px;
  }
  .main__location_icon {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
  }
  .main__location_marker {
    height: 40px;
    width: 30px;
  }
}

@media only screen and (max-width: 1050px) {
  .main__dot_1 {
    width: 1.5em;
  }

  .main__dot_2 {
    margin-left: 1em;
  }

  .main__dot_3 {
    margin-left: 1em;
  }

  .main__dot_4 {
    margin-left: 1em;
  }

  .main__dot_5 {
    margin-left: 2em;
  }

  .main__dot_6 {
    margin-left: 2em;
  }

  .main__dot_7 {
    margin-left: 2em;
  }

  .main__dot_8 {
    margin-left: 2em;
  }

  .main__dot_9 {
    margin-left: 2em;
  }
}

@media only screen and (max-width: 974px) {
  .main__location_address {
    padding: 20px;
    line-height: 18px;
  }
  .main__location_coordinates p {
    font-size: 9px;
  }
}

@media only screen and (max-width: 365px) {
  .main__location_coordinates p {
    font-size: 8px;
  }
}
