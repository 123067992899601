.StudioEmpty__artists__container {
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  display: flex;
  align-items: flex-start;
  justify-content: right;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  height: calc(100% - 55px);
}

.StudioEmpty__artists__container:hover {
  cursor: url("../../assets/images/arrowDown.svg") 10 30, auto;
}

::-webkit-scrollbar {
  display: none;
}
.StudioEmpty__artist__wrapper {
  width: 100%;
  height: 99%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  border-bottom: 1px black solid;
  overflow-y: scroll;
}

.StudioEmpty__artist {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 97%;
  height: 50%;
  min-height: 50%;
}

.StudioEmpty__artist:nth-child(1) {
  border-bottom: 1px solid black;
}

.StudioEmpty__artist:nth-child(2) {
  border-bottom: 1px solid black;
}

.StudioEmpty__artist_img {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 25%;
  height: 100%;
  margin-right: 1em;
}

.StudioEmpty__artist_controls {
  display: flex;
  align-items: center;
  width: 100%;
  height: 75%;
}

.StudioEmpty__artist_controls a:nth-child(1) {
  margin-right: 0.3em;
}

.StudioEmpty__artist_controls a:nth-child(3) {
  margin-left: 0.3em;
}

.StudioEmpty__artist_controls a > img {
  width: 8px;
  object-fit: cover;
  height: 14px;
}

.StudioEmpty__artist__letter {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 25%;
}

.StudioEmpty__artist__letter p {
  margin: 0;
}

.StudioEmpty__artist__letter > p > span {
  font-family: "Aux Mono";
  font-size: 2em;
  padding: 0;
  margin: 0 0 0 0.3em;
}

.StudioEmpty__image_carousel {
  position: relative;
}

.StudioEmpty__image_carousel img {
  width: 127px;
  height: 127px;
  object-fit: cover;
}

.StudioEmpty__artist_text {
  display: flex;
  flex-direction: column;
  width: 60%;
  height: 100%;
  align-items: flex-start;
  justify-content: center;
  margin-left: 1em;
}

.StudioEmpty__artist_text p {
  margin: 0.3em 0 0em 0.5em;
  font-size: 1.8em;
  font-weight: lighter;
  font-family: "Aux Mono";
}

.StudioEmpty__artist_text p:nth-child(1) {
  margin-top: 0.3em;
}

.StudioEmpty__artist_links {
  height: 100%;
  width: 14%;
  margin-right: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.StudioEmpty__artist_insta {
  margin-top: 1.4em;
}

.StudioEmpty__artist_insta a > img {
  width: 23px;
}

.StudioEmpty__artist_more a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-decoration: none;
  color: black;
}

.StudioEmpty__artist_more {
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.StudioEmpty__artist_more img {
  width: 40px;
}

.StudioEmpty__artist_more p {
  font-family: "Oxygen Mono";
  width: 59%;
  font-size: 0.55em;
  white-space: nowrap;
  margin-right: 0.5em;
}

.StudioEmpty__artist__scroll {
  height: 100%;
  width: 5px;
}
.StudioEmptyScroll__vertical_container {
  border-left: 1px solid black;
  width: 100%;
  height: 100%;
  position: relative;
}

.dot__vertical {
  position: absolute;
  background-color: black;
  width: 5px;
}

.dot__vertical:nth-child(1) {
  height: 15px;
}

.dot__vertical:nth-child(2) {
  height: 25px;
}

.dot__vertical:nth-child(3) {
  height: 20px;
}

.dot__vertical:nth-child(4) {
  height: 10px;
}

.dot__vertical:nth-child(5) {
  height: 28px;
}
/* WIDTH MEDIA QUERIES */

@media only screen and (max-width: 1250px) {
  .StudioEmpty__artist_text p {
    margin-left: 1em;
  }
}

@media only screen and (max-width: 1250px) {
  .StudioEmpty__artist_text p {
    margin-left: 1.3em;
  }
}

@media only screen and (max-width: 1050px) {
  .StudioEmpty__artist_text p {
    margin-left: 1.5em;
  }
}

/* HEIGHT MEDIA QUERIES */

@media only screen and (max-height: 850px) {
  .StudioEmpty__artist_img img {
    width: 120px;
  }

  .StudioEmpty__image_carousel img {
    width: 122px;
    height: 122px;
  }
  /* .StudioEmpty__artist_img span {
    font-size: 1.9em;
  } */

  .StudioEmpty__artist__letter > p > span {
    font-size: 1.8em;
  }

  .StudioEmpty__artist_text p {
    font-size: 1.6em;
  }
  .StudioEmpty__artist_more p {
    font-size: 9px;
  }
  .StudioEmpty__artist_text p:nth-child(1) {
    margin-top: 0.5em;
  }
}

@media only screen and (max-height: 780px) {
  .StudioEmpty__artist_img img {
    width: 100px;
  }

  .StudioEmpty__image_carousel img {
    width: 102px;
    height: 102px;
  }

  .StudioEmpty__artist_text p:nth-child(1) {
    margin-top: 0.3em;
  }

  .StudioEmpty__artist__letter > p > span {
    font-size: 1.8em;
  }
}

@media only screen and (max-height: 730px) {
  .StudioEmpty__artist_img img {
    width: 80px;
  }

  .StudioEmpty__image_carousel img {
    width: 82px;
    height: 82px;
  }

  .StudioEmpty__artist_text p {
    font-size: 1.3em;
  }

  .StudioEmpty__artist_controls a > img {
    width: 6px;
  }

  .StudioEmpty__artist__letter > p > span {
    font-size: 1.5em;
  }
  .StudioEmpty__artist_more p {
    font-size: 9px;
  }
}

@media only screen and (max-height: 650px) {
  .StudioEmpty__artist_img img {
    width: 70px;
  }

  .StudioEmpty__image_carousel img {
    width: 72px;
    height: 72px;
  }

  .StudioEmpty__artist_text p {
    font-size: 1.1em;
  }

  .StudioEmpty__artist_controls a > img {
    width: 6px;
  }

  /* .StudioEmpty__artist_img span {
    font-size: 1.3em;
  } */

  .StudioEmpty__artist__letter > p > span {
    font-size: 1.2em;
  }
  .StudioEmpty__artist_more p {
    font-size: 8px;
  }
}
