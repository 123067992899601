.AppointmentSmall__stickyBars {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  /* height: 100%; */
  justify-content: space-between;
  align-items: center;
}

.AppointmentSmall__stickyBar_top_container {
  width: 100%;
  margin-top: 15em;
  display: flex;
  justify-content: center;
}

.AppointmentSmall__stickyBar_top {
  border: 1px solid black;
  height: 5px;
  width: 80%;
}

.AppointmentSmall__container {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.AppointmentSmall__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.AppointmentSmall__Header_contaner {
  height: 14.8em;
  display: flex;
  align-items: flex-end;
  margin-bottom: 0.8em;
}

.AppointmentSmall__maincontent_wrapper {
  width: 100%;
  height: calc(100% - 14.8em);
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.AppointmentSmall__maincontent {
  width: 100%;
  height: 100%;
}

.AppointmentSmall__title_container {
  margin-top: 2em;
  margin-left: 0.3em;
  width: 100%;
}

.AppointmentSmall__form_container {
  margin-top: 3em;
  width: 99%;
  height: 440px;
}

.AppointmentSmall__game_container {
  margin-top: 2em;
  width: 98%;
}

.AppointmentSmall__infodate_container {
  margin-top: 2em;
  padding-bottom: 1em;
  width: 99%;
}

/* HEIGHT MEDIA QUERIES */

@media only screen and (max-height: 830px) {
  .AppointmentSmall__Header_contaner {
    height: 14.8em;
  }

  .AppointmentSmall__maincontent_wrapper {
    height: calc(100% - 14.8em);
  }
}

@media only screen and (max-height: 780px) {
  .AppointmentSmall__Header_contaner {
    height: 14.8em;
  }

  .AppointmentSmall__maincontent_wrapper {
    height: calc(100% - 14.8em);
  }

  .AppointmentSmall__form_container {
    margin-top: 3em;
    width: 99%;
    height: 370px;
  }
}

@media only screen and (max-height: 730px) {
  .AppointmentSmall__Header_contaner {
    height: 15em;
  }

  .AppointmentSmall__maincontent_wrapper {
    height: calc(100% - 15em);
  }

  .AppointmentSmall__form_container {
    margin-top: 3em;
    width: 99%;
    height: 330px;
  }
}

@media only screen and (max-height: 650px) {
  .AppointmentSmall__Header_contaner {
    height: 15em;
  }

  .AppointmentSmall__maincontent_wrapper {
    height: calc(100% - 15em);
  }

  .AppointmentSmall__form_container {
    margin-top: 3em;
    width: 99%;
    height: 310px;
  }
}
