.StudioSmall__container {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  touch-action: none;
  -ms-touch-action: none;
}

.StudioSmall__wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
  touch-action: none;
  -ms-touch-action: none;
}

.StudioSmall__Header_contaner {
  height: 15.5em;
  display: flex;
  align-items: flex-end;
}

.StudioSmall__maincontent_wrapper {
  margin-top: 0.8em;
  height: calc(100% - 11.5em);
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.StudioSmall__maincontent {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.StudioSmall__section_1 {
  width: 100%;
}

.StudioSmall__section_1 > h3 {
  font-family: "Neue Montreal";
  font-size: 1.3em;
  font-weight: bold;
  letter-spacing: 3px;
  word-spacing: 5px;
  margin: 2.3em 0 1em 0;
  white-space: nowrap;
}
.StudioSmall__section_1 p {
  font-family: "Oxygen Mono";

  font-size: 0.8em;
  text-align: justify;
  font-weight: 500;
}

.StudioSmall__section_1 p:nth-child(2) {
  text-align: justify;
  font-family: "Aux Mono";
}

.StudioSmall__section_2 {
  width: 100%;
}

.StudioSmall__section_2 p {
  font-family: "Oxygen Mono";

  font-size: 0.8em;
  text-align: justify;
}

.StudioSmall__section_2 p:nth-child(1) {
  font-weight: 500;
  font-family: "Aux Mono";
}

.StudioSmall__section_3 {
  width: 100%;
}

.StudioSmall__section_3 > h3 {
  font-size: 1.3em;
  font-family: "Neue Montreal";
}

.StudioSmall__box {
  background-color: black;
  color: white;
  font-family: "Neue Montreal";
}

.StudioSmall__scrollbar_container {
  width: 99.5%;
}

/* WIDTH MEDIA QUERIES */

@media only screen and (max-width: 545px) {
  .StudioSmall__Header_contaner {
    height: 16.3em;
  }

  .StudioSmall__section_1 p {
    font-size: 0.7em;
  }

  .StudioSmall__section_2 p {
    font-size: 0.7em;
  }

  .StudioSmall__section_3 > h3 {
    font-size: 1em;
  }

  .StudioSmall__section_1 h3 {
    font-size: 1em;
  }
}

@media only screen and (max-width: 440px) {
  .StudioSmall__Header_contaner {
    min-height: 195px;
  }

  .StudioSmall__scrollbar_container {
    width: 98.5%;
  }
}
