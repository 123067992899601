.main__info_side {
  border: 1px solid black;
  width: 100%;
  max-height: 50px;
  padding: 0.5em 1em 0.5em 1em;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.main__info_qr {
  width: 30px;
  height: 30px;
  margin-right: 2em;
}

.main__info__wrapper {
  display: flex;
  /* outline: red solid; */
  justify-content: center;
  align-items: center;
}

.main__info {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.main__info p {
  margin: 0 1em 0 0;
  font-size: 12px;
  font-family: "Oxygen Mono";
  text-align: right;
  word-spacing: 4px;
  font-weight: lighter;
}

.main__info p span {
  font-family: "Oxygen Mono";
  font-weight: bold;
}

.main_network_wrap {
  width: 50px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main_network {
  width: 100%;
}

/* HEIGHT MEDIA QUERIES */

@media only screen and (max-height: 830px) {
  .main__info p {
    font-size: 11px;
  }
}

@media only screen and (max-height: 780px) {
  .main__info p {
    font-size: 10px;
  }
}

@media only screen and (max-height: 730px) {
  .main__info_qr {
    width: 25px;
    height: 25px;
  }
  .main_network_wrap {
    width: 45px;
    height: 25px;
  }
  .main__info p {
    font-size: 9px;
  }
}

@media only screen and (max-height: 690px) {
  .main__info_qr {
    width: 20px;
    height: 20px;
  }
  .main_network_wrap {
    width: 40px;
    height: 20px;
  }
  .main__info p {
    font-size: 8px;
  }
}

@media only screen and (max-height: 650px) {
  .main__info_qr {
    width: 20px;
    height: 20px;
    position: relative;
    bottom: 0.3em;
  }
}
