.Kosmo__container {
  width: 80%;
  height: 100%;
  flex-direction: column;
}

.Kosmo__wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.Kosmo__Header__container {
  height: 260px;
  display: flex;
  align-items: flex-end;
}

.Kosmo__content_wrapper {
  height: calc(94% - 260px);
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
}

.Kosmo__content_wrapper > p {
  margin: 0.5em 0 0.5em 0;
  /* margin: 0.5em; */
  font-family: "Neue Montreal";
  font-size: 32px;
  font-weight: bold;
}

.Kosmo__box_container {
  width: 100%;
  border: 1px solid black;
  height: calc(100% - 38px);
}

.Kosmo__box_header {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 2em;
  border-bottom: 1px solid black;
  overflow-x: hidden;
  position: relative;
}

.Kosmo__box_header_content {
  width: 100%;
  font-size: 15px;
  white-space: nowrap;
  text-align: center;
  word-spacing: 10px;
  letter-spacing: 3px;
  position: relative;
}

.Kosmo__box_wrapper_content {
  width: 100%;
  height: calc(100% - 2em);
  display: flex;
  justify-content: space-between;
}

.Kosmo__content_left {
  margin: 0.5em 0em 1em 1em;
  width: 30%;
  height: calc(100% - 1em);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-end;
}

.Kosmo__card_container {
  width: 100%;
  height: 93%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  position: relative;
  display: flex;
}

.Kosmo_float_numbers {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.8);
  margin-right: 2em;
  font-size: 0.7em;
  height: 3%;
  font-family: "Oxygen Mono";
}

.Kosmo_float_code {
  position: relative;
  left: 0;
  top: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  font-weight: lighter;
  color: rgba(0, 0, 0, 0.7);
  font-size: 6px;
  letter-spacing: 2.5px;
  font-family: "Oxygen Mono";
  transform: rotate(270deg);
}
.Kosmo__content_right {
  margin: 2em 3em 1em 1em;
  /* width: 60%; */
  max-width: calc(70% - 5.1em);
  height: calc(100% - 4em);
  flex-direction: column;
  display: flex;
  justify-content: start;
  align-items: flex-start;
  text-align: justify;
  font-size: 15px;
}

.Kosmo__content_right_content {
  height: 90%;
  width: 100%;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  display: flex;
  position: relative;
}
::-webkit-scrollbar {
  display: none;
}
.Kosmo__content_right_content_text {
  font-family: "Oxygen Mono";
  word-spacing: 5px;
  font-size: 15px;
  text-align: justify;
  height: 95%;
  line-height: 1.4;
  width: 100%;
}

.Kosmo__content_right_content_button {
  border-right: 2px solid black;
  border-bottom: 2px solid black;
  width: 10em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1px 1px 0;
  margin-top: 1em;
}

.Kosmo__content_right_content_button a {
  text-decoration: none;
  color: black;
  font-family: "Cygnito Mono";
  font-size: 14px;
  color: #404040;
  border: solid gray 1px;
  width: 100%;
  height: 100%;
  padding: 3px;
  text-align: center;
  display: block;
}

.Kosmo__content_right_bottom {
  margin-top: 1.8em;
  display: flex;
  height: 10%;
  width: 100%;
  align-items: flex-end;
  justify-content: space-between;
}

.Kosmo__content_right_bottom img {
  width: 100%;
  height: 100%;
}

.Kosmo__content_img:nth-child(1) {
  height: 30px;
}

.Kosmo__content_img:nth-child(2) {
  height: 30px;
}

.Kosmo__content_img:nth-child(3) {
  height: 30px;
}

.Kosmo__content_img:nth-child(4) {
  width: 210px;
  margin-right: 1em;
}
.Kosmo__content_img:nth-child(5) {
  height: 25px;
}

.Kosmo__content_img:nth-child(6) {
  height: 25px;
}
.Kosmo__content_img:nth-child(7) {
  height: 25px;
}
/* WIDTH MEDIA QUERIES */

@media only screen and (max-width: 1290px) {
  .Kosmo__content_right_content_text {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1250px) {
  .Kosmo__content_img:nth-child(1) {
    height: 25px;
  }

  .Kosmo__content_img:nth-child(2) {
    height: 25px;
  }

  .Kosmo__content_img:nth-child(3) {
    height: 25px;
  }

  .Kosmo__content_img:nth-child(4) {
    width: 190px;
    margin-right: 1em;
  }

  .Kosmo__content_img:nth-child(5) {
    height: 25px;
  }

  .Kosmo__content_img:nth-child(6) {
    height: 25px;
  }
  .Kosmo__content_img:nth-child(7) {
    height: 25px;
  }
}

@media only screen and (max-width: 1150px) {
  .Kosmo__content_img:nth-child(1) {
    height: 20px;
  }

  .Kosmo__content_img:nth-child(2) {
    height: 20px;
  }

  .Kosmo__content_img:nth-child(3) {
    height: 20px;
  }

  .Kosmo__content_img:nth-child(4) {
    width: 170px;
    margin-right: 1em;
  }

  .Kosmo__content_img:nth-child(5) {
    height: 20px;
  }

  .Kosmo__content_img:nth-child(6) {
    height: 20px;
  }
  .Kosmo__content_img:nth-child(7) {
    height: 20px;
  }
}

@media only screen and (max-width: 1120px) {
  .Kosmo__content_right_content_text {
    font-size: 13px;
  }
  .Kosmo__content_right {
    margin: 1em 2.5em 1em 0em;
  }
}

@media only screen and (max-width: 1025px) {
  .Kosmo__content_right_content_text {
    font-size: 12px;
  }
  .Kosmo__content_right {
    margin: 1em 2em 1em 0em;
  }
}

/* HEIGHT MEDIA QUERIES */

@media only screen and (max-height: 920px) {
  .Kosmo__Header__container {
    height: 235px;
  }

  .Kosmo__content_wrapper {
    height: calc(94% - 235px);
  }

  .Kosmo_float_code {
    font-size: 6px;
    letter-spacing: 2.2px;
  }

  .Kosmo__box_container {
    height: calc(100% - 38px);
  }

  .Kosmo_float_numbers {
    font-size: 0.6em;
  }
}

@media only screen and (max-height: 900px) {
  .Kosmo__Header__container {
    height: 205px;
  }

  .Kosmo__content_wrapper {
    height: calc(94% - 205px);
  }
}

@media only screen and (max-height: 830px) {
  .Kosmo_float_code {
    font-size: 5.5px;
    letter-spacing: 2px;
  }

  .Kosmo_float_numbers {
    font-size: 0.5em;
  }

  .Kosmo__Header__container {
    height: 205px;
  }

  .Kosmo__box_container {
    height: calc(100% - 24px);
  }

  .Kosmo__content_wrapper {
    height: calc(94% - 205px);
  }

  .Kosmo__content_right_content_text {
    font-size: 13px;
  }

  .Kosmo__content_wrapper > p {
    font-size: 24px;
  }
}

@media only screen and (max-height: 780px) {
  .Kosmo__content_right_content_text {
    font-size: 12px;
  }

  .Kosmo__content_wrapper > p {
    font-size: 20px;
  }

  .Kosmo_float_code {
    font-size: 5px;
    letter-spacing: 1.6px;
  }
  .Kosmo__box_container {
    height: calc(100% - 20px);
  }
}

@media only screen and (max-height: 730px) {
  .Kosmo_float_code {
    font-size: 4.5px;
    letter-spacing: 1px;
  }

  .Kosmo_float_numbers {
    font-size: 0.45em;
  }

  .Kosmo__Header__container {
    height: 180px;
  }

  .Kosmo__content_wrapper {
    height: calc(94% - 180px);
  }

  .Kosmo__content_right_content_text {
    font-size: 11px;
  }

  .Kosmo__content_wrapper > p {
    font-size: 18px;
  }
  .Kosmo__box_container {
    height: calc(100% - 18px);
  }
}

@media only screen and (max-height: 650px) {
  .Kosmo__Header__container {
    height: 180px;
  }

  .Kosmo__content_right_content_text {
    font-size: 10px;
  }
  .Kosmo__box_container {
    height: 415px;
  }
  .Kosmo__content_wrapper > p {
    font-size: 16px;
  }
  .Kosmo__box_container {
    height: calc(100% - 16px);
  }

  .Kosmo_float_code {
    font-size: 4px;
    letter-spacing: 0.8px;
  }
}
