.DesignSmall__container {
  width: 80%;
  height: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  touch-action: none;
  -ms-touch-action: none;
}

.DesignSmall__wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
  touch-action: none;
  -ms-touch-action: none;
}

.DesignSmall__Header_container {
  height: 15.5em;
  display: flex;
  align-items: flex-end;
}

.DesignSmall__maincontent_wrapper {
  margin-top: 0.8em;
  width: 100%;
  height: calc(100% - 11.5em);
  overflow: hidden;
}

.DesignSmall__maincontent {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.DesignSmall__subtitle,
.DesignSmall__subtitle2 {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

.DesignSmall__subtitle h3 {
  margin: 2.3em 0 1em 5px;
  font-family: "Neue Montreal";
  font-size: 1.2em;
  letter-spacing: 3px;
  word-spacing: 4px;
}

.DesignSmall__subtitle2 h3 {
  margin: 0.5em 0 0.5em 0;
  font-family: "Neue Montreal";
  font-size: 1.2em;
  letter-spacing: 3px;
  word-spacing: 4px;
}

.DesignSmall__subtitle img {
  margin: 2.3em 0 1em 0px;
  /* position: absolute; */
  width: 210px;
  top: 2.7em;
  left: 0em;
}

.DesignSmall__subtitle2 img {
  /* position: absolute; */
  margin: 0.5em 0 1em 0;
  width: 440px;
  top: 8px;
  left: 50px;
}

.DesignSmall__subtitle span:nth-child(2) {
  background-color: black;
  color: white;
}

.DesignSmall__subtitle2 span:nth-child(2) {
  background-color: black;
  color: white;
}
.DesignSmall__box_text {
  width: 100%;
}

.DesignSmall__box_text p {
  font-family: "Oxygen Mono";
  text-align: justify;
}

/* WIDTH MEDIA QUERIES */

@media only screen and (max-width: 545px) {
  .DesignSmall__Header_container {
    height: 16.3em;
  }

  .DesignSmall__box_text p {
    font-size: 0.7em;
  }
}

@media only screen and (max-width: 474px) {
  .DesignSmall__subtitle h3 {
    font-size: 0.95em;
  }
  .DesignSmall__subtitle2 h3 {
    font-size: 0.95em;
    margin-bottom: 1.5em;
  }

  .DesignSmall__subtitle img {
    width: 130px;
  }

  .DesignSmall__subtitle2 img {
    width: 270px;
  }
}

@media only screen and (max-width: 440px) {
  .DesignSmall__Header_container {
    min-height: 195px;
  }
}
