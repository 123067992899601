.About__container {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.About__wrapper {
  display: flex;
  flex-direction: column;
  height: calc(100% - 118px);
  width: 100%;
}

.About__Header__container {
  height: 260px;
  display: flex;
  align-items: flex-end;
}

.About__content_wrapper {
  height: calc(100% - 260px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.About__subtitle {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

.About__subtitle h3 {
  font-family: "Neue Montreal";
  font-size: 32px;
  margin: 0 0 1em 14px;
  letter-spacing: 3px;
}

.About__subtitle img {
  /* position: absolute; */
  margin: 0 0 1em 0;
  width: 600px;
  left: 0px;
  top: 0px;
}

.About__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 426px;
}

.About__content_text {
  width: 32%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.About__content_text p {
  font-family: "Oxygen Mono";
  font-size: 15px;
  text-align: justify;
  margin: 0em 0 2em 0;
  line-height: 1.1;
}

.About__content_box {
  width: 55%;
  height: 100%;
  margin: 0 5px 0 10px;
  position: relative;
  display: flex;
}

.About__content_box > p {
  position: absolute;
  width: 5%;
  transform: rotate(90deg);
  right: 0;
  top: 11em;
  margin: 0;
  letter-spacing: 2px;
  word-spacing: 5px;
  white-space: nowrap;
  font-family: "Oxygen Mono";
  font-size: 10.69px;
}

.About__content_image {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.About__content_image img {
  height: 100%;
  object-fit: contain;
}

/* WIDTH MEDIA QUERIES */

@media only screen and (max-width: 1290px) {
  .About__content_text p {
    font-size: 14px;
  }
  .About__subtitle h3 {
    font-size: 28px;
  }
  .About__subtitle img {
    width: 170px;
    left: 0px;
  }
}

@media only screen and (max-width: 1120px) {
  .About__content_text p {
    font-size: 13px;
  }
}

@media only screen and (max-width: 1025px) {
  .About__content_text p {
    font-size: 12px;
  }
}

/* HEIGHT MEDIA QUERIES */

@media only screen and (max-height: 920px) {
  .About__Header__container {
    height: 235px;
  }

  .About__content_wrapper {
    height: calc(100% - 235px);
  }
}

@media only screen and (max-height: 900px) {
  .About__Header__container {
    height: 205px;
  }

  .About__content_wrapper {
    height: calc(100% - 205px);
  }

  .About__wrapper {
    height: calc(100% - 100px);
  }
}

@media only screen and (max-height: 830px) {
  .About__Header__container {
    height: 205px;
  }

  .About__content_wrapper {
    height: calc(100% - 205px);
  }

  .About__wrapper {
    height: calc(100% - 100px);
  }

  .About__content {
    height: 390px;
  }

  .About__content_text p {
    font-size: 13px;
  }

  .About__content_box > p {
    top: 13em;
    font-size: 9px;
  }

  .About__subtitle h3 {
    font-size: 24px;
    margin: 0 0 1em 12px;
  }
  .About__subtitle img {
    width: 400px;
  }
}

@media only screen and (max-height: 780px) {
  .About__wrapper {
    height: calc(100% - 80px);
  }

  .About__content {
    height: 360px;
  }

  .About__content_text p {
    font-size: 12px;
  }

  .About__content_box > p {
    top: 14em;
    font-size: 8px;
  }

  .About__subtitle h3 {
    font-size: 20px;
    margin: 0 0 1em 10px;
  }
  .About__subtitle img {
    width: 390px;
  }
}

@media only screen and (max-height: 730px) {
  .About__Header__container {
    height: 180px;
  }

  .About__content_wrapper {
    height: calc(100% - 180px);
  }

  .About__wrapper {
    height: calc(100% - 80px);
  }

  .About__content {
    height: 300px;
  }

  .About__content_text p {
    font-size: 11px;
  }

  .About__content_box > p {
    top: 11em;
    font-size: 7px;
  }

  .About__subtitle h3 {
    font-size: 18px;
    margin: 0 0 1em 10px;
  }
}

@media only screen and (max-height: 650px) {
  .About__Header__container {
    height: 180px;
  }

  .About__content_wrapper {
    height: calc(100% - 180px);
  }

  .About__wrapper {
    height: calc(100% - 70px);
  }

  .About__content {
    height: 270px;
  }

  .About__content_text p {
    font-size: 10px;
  }

  .About__content_box > p {
    top: 11em;
    font-size: 6px;
  }

  .About__subtitle h3 {
    font-size: 16px;
    margin: 0 0 1em 10px;
  }
  .About__subtitle img {
    width: 380px;
  }
}
