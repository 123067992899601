.AboutSmall__container {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  touch-action: none;
  -ms-touch-action: none;
}

.AboutSmall__wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /* background-color: red; */
  overflow: hidden;
  touch-action: none;
  -ms-touch-action: none;
}

.AboutSmall__Header_container {
  height: 15.5em;
  margin-top: 0em;
  display: flex;
  align-items: flex-end;
}

.AboutSmall__maincontent_wrapper {
  margin-top: 0.8em;
  width: 100%;
  height: calc(100% - 11.5em);
  overflow: hidden;
}

.AboutSmall__maincontent {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.AboutSmall__box_text {
  width: 100%;
}

.AboutSmall__box_text p {
  font-family: "Oxygen Mono";
  text-align: justify;
}

.AboutSmall__subtitle {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

.AboutSmall__subtitle img {
  /* position: absolute; */
  width: 400px;
  left: 0px;
  top: 2.7em;
  margin: 2.3em 0 1em 0;
}

.AboutSmall__subtitle h3 {
  margin: 2.3em 0 1em 5px;
  font-family: "Neue Montreal";
  font-size: 1.2em;
  letter-spacing: 3px;
  word-spacing: 4px;
}

.AboutSmall__statuttle_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.AboutSmall__statuttle_text {
  width: 80%;
}

.AboutSmall__statuttle_text p {
  font-family: "Oxygen Mono";
  font-size: 1em;
  text-align: justify;
}

.AboutSmall__statuttle_image {
  width: 20%;
  display: flex;
  justify-content: flex-end;
}

.AboutSmall__statuttle_image img {
  width: 80px;
}

.AboutSmall__grid_container {
  width: 100%;
  height: 400px;
  margin-top: 1em;
  margin-bottom: 1em;
  position: relative;
}

.AboutSmall__grid_container > p {
  width: 5%;
  position: absolute;
  transform: rotate(90deg);
  right: 0;
  top: 13em;
  font-family: "Oxygen Mono";
  font-size: 0.7em;
  white-space: nowrap;
}

.AboutSmall__grid {
  width: 95%;
  height: 100%;
}

/* WIDTH MEDIA QUERIES */

@media only screen and (max-width: 545px) {
  .AboutSmall__Header_container {
    height: 16.3em;
  }

  .AboutSmall__statuttle_text {
    width: 70%;
  }

  .AboutSmall__box_text p {
    font-size: 0.7em;
  }

  .AboutSmall__statuttle_text p {
    font-size: 0.7em;
  }

  .AboutSmall__subtitle h3 {
    font-size: 0.95em;
  }

  .AboutSmall__subtitle img {
    width: 280px;
  }
}

@media only screen and (max-width: 440px) {
  .AboutSmall__Header_container {
    min-height: 195px;
    /* margin-top: 1em; */
  }

  
}
