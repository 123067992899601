.DesignBox__container {
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  height: calc(100% - 55px);
  display: flex;
  align-items: flex-start;
  justify-content: right;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.DesignBox__container:hover {
  cursor: url("../../assets/images/arrowDown.svg"), auto;
}

::-webkit-scrollbar {
  display: none;
}

.DesignBox__wrapper {
  width: 100%;
  height: 99%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: flex-end;
  margin-bottom: 0.2em;
  border-bottom: 1px black solid;
  overflow-y: scroll;
}

.DesignBox__item__container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 98%;
  height: 50%;
  min-height: 50%;
}

.DesignBox__item__wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.DesignBox__item__container:nth-child(1) {
  border-bottom: 1px solid black;
}
.DesignBox__item__container:nth-child(2) {
  border-bottom: 1px solid black;
}
.DesignBox__item__container:nth-child(3) {
  border-bottom: 1px solid black;
}
.DesignBox__item__container:nth-child(4) {
  border-bottom: 1px solid black;
}

.DesignBox__item__left {
  height: 100%;
  width: 60%;
}

.DesignBox__item__left > p {
  font-size: 15px;
  font-family: "Oxygen Mono";
  margin: 0px 0 0 0;
}

.DesignBox__item_head {
  display: flex;
  align-items: center;
}

.DesignBox__item_head p {
  font-size: 16px;
  text-transform: uppercase;
  font-family: "Aux Mono";
  margin: 0.5em 0 1px 0
}

.DesignBox__item_head img {
  margin-right: 10px;
}

.DesignBox__item__right {
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.DesignBox__item__right img {
  width: 75%;
  height: 75%;
}

.DesignBox__item_head_table {
  width: 100%;
  display: flex;
}

.DesignBox__item_head_table p {
  font-size: 1em;
  text-transform: uppercase;
  font-family: "Aux Mono";
}

.DesignBox__item_head_table img {
  margin-right: 10px;
}

.DesignBox__table__container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 98%;
  margin-top: 1em;
  min-height: 50%;
}

.DesignBox__item__wrapper_table {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.DesignBox__table {
  height: 100%;
  width: 100%;
  margin-bottom: 0.5em;
}

.DesignBox__table table {
  width: 100%;
  height: 90%;
  border-spacing: 0;
  border-top: 1px solid black;
}

.DesignBox__table table td {
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  font-family: "Oxygen Mono";
  font-size: 0.9em;
  height: 1.5em;
  text-align: center;
}

.DesignBox__table table td:nth-child(1) {
  border-left: 0px solid black;
}

/* WIDTH MEDIA QUERIES */

@media only screen and (max-width: 1290px) {
  .DesignBox__item_head p {
    font-size: 14px;
  }
  .DesignBox__item__left > p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1120px) {
  .DesignBox__item_head p {
    font-size: 13px;
  }
  .DesignBox__item__left > p {
    font-size: 13px;
  }
}

@media only screen and (max-width: 1105px) {
  .DesignBox__item_head p {
    font-size: 13px;
    margin-bottom: 0;
  }
  .DesignBox__item__left > p {
    font-size: 13px;
  }
}

@media only screen and (max-width: 1025px) {
  .DesignBox__item_head p {
    font-size: 12px;
  }
  .DesignBox__item__left > p {
    font-size: 12px;
  }
}

/* HEIGHT MEDIA QUERIES */

@media only screen and (max-height: 830px) {
  .DesignBox__item_head p {
    font-size: 14px;
  }

  .DesignBox__item_head img {
    width: 11px;
  }

  .DesignBox__item__left > p {
    font-size: 13px;
  }
}

@media only screen and (max-height: 780px) {
  .DesignBox__container {
    height: calc(90% - 55px);
  }

  .DesignBox__item_head p {
    font-size: 13px;
  }

  .DesignBox__item__left > p {
    font-size: 11px;
  }
}

@media only screen and (max-height: 775px) {
  .DesignBox__container {
    height: calc(90% - 55px);
  }
}

@media only screen and (max-height: 750px) {
  .DesignBox__item_head p {
    font-size: 12px;
  }
  .DesignBox__item_head_table p {
    font-size: 12px;
  }
  .DesignBox__item_head_table {
    margin-top: 1.5em;
  }
  .DesignBox__table table td {
    font-size: 10px;
    height: 2em;
  }
  .DesignBox__item__left > p {
    font-size: 10px;
    font-family: "Oxygen Mono";
  }
}

@media only screen and (max-height: 650px) {
  .DesignBox__item_head p {
    font-size: 11px;
  }
  .DesignBox__item_head_table p {
    font-size: 11px;
  }
  .DesignBox__item__left > p {
    font-size: 9px;
    font-family: "Oxygen Mono";
  }
  .DesignBox__item_head_table {
    margin-top: 2em;
  }
}
