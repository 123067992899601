.homeSmall__wrapper {
  width: 100%;
  height: 100%;
  padding-bottom: 13%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.homeSmall__logo_wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 95%;
}

.homeSmall__logo_text {
  text-align: center;
  font-family: "Oxygen Mono";
  font-size: 21px;
  margin-top: 0.6em;
  padding: 0;
  font-weight: normal;
  word-spacing: 5px;
  letter-spacing: 20px;
  z-index: 1;
  white-space: nowrap;
}

@media only screen and (max-width: 900px) {
  .homeSmall__logo_text {
    font-size: 18px;
  }
}

@media only screen and (max-width: 750px) {
  .homeSmall__logo_text {
    font-size: 16px;
    word-spacing: 5px;
    letter-spacing: 18px;
  }
}

@media only screen and (max-width: 700px) {
  .homeSmall__logo_text {
    font-size: 14px;
  }
}

@media only screen and (max-width: 600px) {
  .homeSmall__logo_text {
    letter-spacing: 16px;
  }
}

@media only screen and (max-width: 550px) {
  .homeSmall__logo_text {
    letter-spacing: 14px;
    font-size: 15px;
  }
}

@media only screen and (max-width: 500px) {
  .homeSmall__logo_text {
    letter-spacing: 12px;
    font-size: 13px;
  }
}

@media only screen and (max-width: 450px) {
  .homeSmall__logo_text {
    letter-spacing: 10px;
    font-size: 11px;
  }
}

@media only screen and (max-width: 400px) {
  .homeSmall__logo_text {
    letter-spacing: 9px;
  }
}

@media only screen and (max-width: 350px) {
  .homeSmall__logo_text {
    letter-spacing: 6px;
    font-size: 10px;
  }
}
