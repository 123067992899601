.HomeSmall_2__container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url("../../assets/images/clock-glitch.png");
  background-position: 72% bottom;
  background-repeat: no-repeat;
  padding-bottom: 3%;
  background-size: 1100px;
}

.HomeSmall_2__wrapper {
  margin: 0px;
  padding: 0px;
  width: 700px;
  padding-bottom: 8%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 1;
}

.HomeSmall_2__phrase {
  font-family: "Neue Montreal medium";
  font-weight: bold;
  font-size: 50px;
  letter-spacing: 7px;
  word-spacing: 3px;
  margin-bottom: 0.2em;
  white-space: nowrap;
}

.HomeSmall_2__phrase_box {
  font-family: "Neue Montreal medium";
  font-weight: bold;
  font-size: 50px;
  letter-spacing: 7px;
  word-spacing: 3px;
  margin-bottom: 0.2em;
  white-space: nowrap;
  position: relative;
}

.HomeSmall_2__phrase_box img {
  position: absolute;
  width: 290px;
  left: -5px;
}

.HomeSmall_2__phrase_small {
  font-family: "Oxygen Mono";
  font-weight: lighter;
  font-size: 15px;
  color: #000;
  letter-spacing: 0px;
  word-spacing: 3px;
  margin-bottom: 0em;
}

@media only screen and (max-width: 850px) {
  .HomeSmall_2__phrase {
    font-size: 40px;
  }

  .HomeSmall_2__phrase_box {
    font-size: 40px;
    padding: 0px 8px 0px 15px;
  }

  .HomeSmall_2__phrase_box img {
    position: absolute;
    width: 250px;
    left: 8px;
  }

  .HomeSmall_2__phrase_small {
    font-size: 12px;
  }

  .HomeSmall_2__wrapper {
    width: 600px;
  }
}

@media only screen and (max-width: 650px) {
  .HomeSmall_2__phrase {
    font-size: 30px;
  }

  .HomeSmall_2__phrase_box {
    font-size: 30px;
  }

  .HomeSmall_2__phrase_box img {
    position: absolute;
    width: 200px;
    left: 10px;
    top: -3px;
  }

  .HomeSmall_2__phrase_small {
    font-size: 12px;
  }

  .HomeSmall_2__wrapper {
    width: 550px;
  }

  .HomeSmall_2__container {
    background-size: 900px;
  }
}

@media only screen and (max-width: 600px) {
  .HomeSmall_2__phrase {
    font-size: 25px;
  }

  .HomeSmall_2__phrase_box img {
    width: 180px;
    left: 10px;
    top: -3px;
  }

  .HomeSmall_2__phrase_box {
    font-size: 25px;
  }

  .HomeSmall_2__phrase_small {
    font-size: 12px;
  }

  .HomeSmall_2__wrapper {
    width: 500px;
  }
}

@media only screen and (max-width: 550px) {
  .HomeSmall_2__wrapper {
    width: 450px;
  }

  .HomeSmall_2__container {
    background-size: 700px;
  }
}

@media only screen and (max-width: 500px) {
  .HomeSmall_2__wrapper {
    width: 400px;
  }
}

@media only screen and (max-width: 450px) {
  .HomeSmall_2__wrapper {
    width: 330px;
  }

  .HomeSmall_2__phrase {
    font-size: 22px;
  }

  .HomeSmall_2__phrase_box img {
    width: 190px;
    left: 6px;
    top: -3px;
  }
}

@media only screen and (max-width: 400px) {
  .HomeSmall_2__wrapper {
    width: 325px;
  }
  .HomeSmall_2__phrase_box {
    font-size: 20px;
  }
  .HomeSmall_2__phrase_box img {
    width: 160px;
    left: 9px;
    top: -3px;
  }

  .HomeSmall_2__phrase {
    font-size: 20px;
  }

  .HomeSmall_2__phrase_small {
    font-size: 11px;
  }
}

@media only screen and (max-width: 350px) {
  .HomeSmall_2__wrapper {
    width: 300px;
  }
  .HomeSmall_2__phrase_box {
    font-size: 18px;
  }

  .HomeSmall_2__phrase_box img {
    width: 150px;
    left: 9px;
    top: -3px;
  }
  .HomeSmall_2__phrase {
    font-size: 18px;
  }

  .HomeSmall_2__phrase_small {
    font-size: 11px;
  }
}
