.Design__container {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.Design__wrapper {
  display: flex;
  flex-direction: column;
  height: calc(100% - 118px);
  width: 100%;
}

.Design__Header__container {
  height: 260px;
  display: flex;
  align-items: flex-end;
}

.Design__content_wrapper {
  height: calc(100% - 260px);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Design__left__container {
  width: 41%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 496px;
}

.Design__right__container {
  width: 55%;
  height: 496px;
  display: flex;

  flex-direction: column;
  justify-content: start;
}
.Design__subtitle__container2,
.Design__subtitle__container {
  width: 100%;
  position: relative;
}

.Design__subtitle__container2 p,
.Design__subtitle__container p {
  font-family: "Neue Montreal";
  font-size: 32px;
  font-weight: bold;
  white-space: nowrap;
  letter-spacing: 4px;
  word-spacing: 6px;
  text-align: start;
  margin: 0 0 0 10px;
}

.Design__subtitle__container span {
  background-color: black;
  color: white;
}

.Design__subtitle__container2 p {
  margin: 0;
}

.Design__subtitle__container img {
  /* position: absolute; */
  width: 300px;
  top: 0px;
  left: 0px;
}
.Design__subtitle__container2 img {
  /* position: absolute; */
  width: 600px;
  top: 0px;
  left: 78px;
}
.Design__subtitle__container a {
  text-decoration: none;
}

.Design__subtitle__box {
  background-color: black;
  color: white;
}

.Design__subtitle__anchor {
  font-weight: lighter;
  color: #313131;
}

.Design__text__container {
  display: flex;
  width: 100%;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  flex-direction: column;
}

.Design__text__left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: justify;
  font-family: "Oxygen Mono";
  margin-top: 1em;
}

.Design__text__left p {
  margin-top: 1em;
  margin-bottom: 0;
  font-size: 15px;
  line-height: 1.1;
}

.Design__text__right {
  width: 48%;
  font-family: "Aux Mono";
  font-size: 16px;
  text-align: justify;
}

.DesignBox__empty_msg {
  font-family: "Aux Mono";
  font-size: 1em;
}

.Design__portal__container {
  height: 435px;
  width: 100%;
  margin-top: 1.5em;
}

.Design__portal__wrapper {
  height: 100%;
  width: 100%;
  /* overflow-y: scroll; */
  display: flex;
  flex-direction: column;
  align-self: center;
  background: transparent;
}

/* WIDTH MEDIA QUERIES */

@media only screen and (max-width: 1290px) {
  .Design__text__left p {
    font-size: 14px;
  }
  .Design__subtitle__container2 p,
  .Design__subtitle__container p {
    font-size: 28px;
  }
  .Design__subtitle__container img {
    width: 180px;
  }

  .Design__subtitle__container2 img {
    width: 200px;
    left: 70px;
    top: -3px;
  }
}

@media only screen and (max-width: 1245px) {
  .Design__subtitle__container2 p,
  .Design__subtitle__container p {
    font-size: 26px;
  }
  .Design__subtitle__container img {
    width: 170px;
  }
  .Design__subtitle__container2 img {
    width: 180px;
    left: 68px;
  }
}

@media only screen and (max-width: 1170px) {
  .Design__subtitle__container2 p,
  .Design__subtitle__container p {
    font-size: 24px;
  }
  .Design__subtitle__container img {
    width: 160px;
  }

  .Design__subtitle__container2 img {
    width: 170px;
    left: 65px;
  }
}

@media only screen and (max-width: 1120px) {
  .Design__text__left p {
    font-size: 13px;
  }
}

@media only screen and (max-width: 1110px) {
  .Design__subtitle__container2 p,
  .Design__subtitle__container p {
    font-size: 22px;
  }
  .Design__subtitle__container img {
    width: 150px;
  }
  .Design__subtitle__container2 img {
    width: 160px;
    left: 60px;
  }
}

@media only screen and (max-width: 1035px) {
  .Design__subtitle__container2 p,
  .Design__subtitle__container p {
    font-size: 20px;
  }
  .Design__subtitle__container img {
    width: 140px;
  }
  .Design__subtitle__container2 img {
    width: 150px;
    left: 57px;
  }
}

@media only screen and (max-width: 1025px) {
  .Design__text__left p {
    font-size: 12px;
  }
}

@media only screen and (max-width: 976px) {
  .Design__subtitle__container2 p,
  .Design__subtitle__container p {
    font-size: 18px;
  }
  .Design__subtitle__container img {
    width: 130px;
  }
  .Design__subtitle__container2 img {
    width: 140px;
    left: 52px;
  }
}

/* HEIGHT MEDIA QUERIES */

@media only screen and (max-height: 920px) {
  .Design__Header__container {
    height: 235px;
  }

  .Design__content_wrapper {
    height: calc(100% - 235px);
  }
}

@media only screen and (max-height: 900px) {
  .Design__Header__container {
    height: 205px;
  }

  .Design__content_wrapper {
    height: calc(100% - 205px);
  }

  .Design__wrapper {
    height: calc(100% - 100px);
  }
}

@media only screen and (max-height: 830px) {
  .Design__Header__container {
    height: 205px;
  }

  .Design__content_wrapper {
    height: calc(100% - 205px);
  }

  .Design__wrapper {
    height: calc(100% - 100px);
  }

  .Design__left__container {
    height: 442px;
  }

  .Design__right__container {
    height: 442px;
  }

  .Design__text__left p {
    margin-top: 1em;
    margin-bottom: 0;
    font-size: 13px;
  }

  .Design__subtitle__container2 p,
  .Design__subtitle__container p {
    font-size: 24px;
  }
  .Design__subtitle__container img {
    width: 230px;
  }
  .Design__subtitle__container2 img {
    width: 510px;
  }

  .Design__portal__container {
    height: 390px;
  }
}

@media only screen and (max-height: 780px) {
  .Design__wrapper {
    height: calc(100% - 80px);
  }

  .Design__left__container {
    height: 405px;
  }

  .Design__right__container {
    height: 405px;
  }

  .Design__text__left p {
    font-size: 12px;
  }
  .Design__subtitle__container2 p,
  .Design__subtitle__container p {
    font-size: 20px;
  }
  .Design__subtitle__container img {
    width: 200px;
  }
  .Design__subtitle__container2 img {
    width: 440px;
  }
  .Design__portal__container {
    height: 355px;
  }
  .Design__portal__wrapper {
    height: 395px;
  }
}

@media only screen and (max-height: 730px) {
  .Design__Header__container {
    height: 180px;
  }

  .Design__content_wrapper {
    height: calc(100% - 180px);
  }

  .Design__wrapper {
    height: calc(100% - 80px);
  }

  .Design__left__container {
    height: 340px;
  }

  .Design__right__container {
    height: 340px;
  }

  .Design__text__left p {
    font-size: 11px;
  }
  .Design__subtitle__container2 p,
  .Design__subtitle__container p {
    font-size: 18px;
  }
  .Design__subtitle__container img {
    width: 200px;
  }
  .Design__subtitle__container2 img {
    width: 440px;
  }
  .Design__portal__container {
    height: 295px;
  }

  .Design__portal__wrapper {
    height: 327px;
  }
}

@media only screen and (max-height: 650px) {
  .Design__Header__container {
    height: 180px;
  }

  .Design__content_wrapper {
    height: calc(100% - 180px);
  }

  .Design__wrapper {
    height: calc(100% - 70px);
  }

  .Design__text__left p {
    font-size: 10px;
  }

  .Design__left__container {
    height: 306px;
  }

  .Design__right__container {
    height: 306px;
  }
  .Design__subtitle__container2 p,
  .Design__subtitle__container p {
    font-size: 16px;
  }
  .Design__subtitle__container img {
    width: 200px;
  }
  .Design__subtitle__container2 img {
    width: 440px;
  }
  .Design__portal__container {
    height: 260px;
  }

  .Design__portal__wrapper {
    height: 290px;
  }
}
