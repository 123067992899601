.Talk__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 80%;
}

.Talk__wrapper {
  display: flex;
  flex-direction: column;
  height: calc(100% - 118px);
  width: 100%;
}

.Talk__header_container {
  height: 260px;
  display: flex;
  align-items: flex-end;
  margin-bottom: 0.5em;
}

.Talk__content_wrapper {
  height: calc(100% - 260px);
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
}

.Talk__content_wrapper > p {
  font-family: "Aux Mono";
  font-size: 30px;
  margin: 0 0 0.4em 1.5em;
}

.Talk__content_container {
  width: 100%;
  max-height: 496px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Talk__left_container {
  width: 51%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.Talk__form {
  width: 97%;
  height: 100%;
}

.Talk__floatNumbers {
  position: relative;
  transform: rotate(270deg);
}

.Talk__floatNumbers p {
  font-family: "Oxygen Mono";
  font-size: 7px;
  letter-spacing: 0.5em;
  white-space: nowrap;
  position: absolute;
  left: -7em;
  top: -1em;
}

.Talk__right_container {
  width: 48%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.CustomForm__error {
  font-family: "Oxygen Mono";
  font-size: 8px;
  margin-right: 2em;
}

/* HEIGHT MEDIA QUERIES */

@media only screen and (max-height: 920px) {
  .Talk__header_container {
    height: 235px;
  }

  .Talk__content_wrapper {
    height: calc(100% - 235px);
  }
}

@media only screen and (max-height: 900px) {
  .Talk__header_container {
    height: 205px;
  }

  .Talk__content_wrapper {
    height: calc(100% - 205px);
  }

  .Talk__wrapper {
    height: calc(100% - 100px);
  }
}

@media only screen and (max-height: 830px) {
  .Talk__header_container {
    height: 205px;
  }

  .Talk__content_wrapper {
    height: calc(100% - 205px);
  }

  .Talk__wrapper {
    height: calc(100% - 100px);
  }

  .Talk__content_container {
    height: 450px;
  }

  .Talk__floatNumbers p {
    font-size: 6px;
    left: -6.5em;
    top: -1em;
  }
}

@media only screen and (max-height: 780px) {
  .Talk__wrapper {
    height: calc(100% - 80px);
  }

  .Talk__content_container {
    height: 405px;
  }

  .Talk__floatNumbers p {
    font-size: 5px;
    left: -4em;
    top: -1em;
  }
}

@media only screen and (max-height: 730px) {
  .Talk__header_container {
    height: 180px;
  }

  .Talk__content_wrapper {
    height: calc(100% - 180px);
  }

  .Talk__wrapper {
    height: calc(100% - 80px);
  }

  .Talk__content_container {
    height: 380px;
  }
  .Talk__floatNumbers p {
    font-size: 5px;
    left: -5em;
    top: -1em;
  }
}

@media only screen and (max-height: 690px) {
  .Talk__content_container {
    height: 340px;
  }
  .Talk__floatNumbers p {
    font-size: 4.5px;
    left: -6em;
    top: -1em;
  }
}

@media only screen and (max-height: 650px) {
  .Talk__header_container {
    height: 180px;
  }

  .Talk__content_wrapper {
    height: calc(100% - 180px);
  }

  .Talk__wrapper {
    height: calc(100% - 70px);
  }

  .Talk__content_container {
    height: 310px;
  }
  .Talk__floatNumbers p {
    font-size: 4px;
    left: -4em;
    top: -1em;
  }
}
