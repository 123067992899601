.top__scores_wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  border: 1px solid black;
  clip-path: polygon(0 0, 100% 0, 100% 50%, 100% 100%, 5% 100%, 0 90%);
  background-color: #f3f3f3;
}

.top__scores_bar {
  width: 33px;
  background-color: black;
  height: 100%;
  color: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  clip-path: polygon(0 0, 100% 0, 100% 50%, 100% 100%, 5% 100%, 0 90%);
  align-items: center;
  position: relative;
}

.top__scores {
  transform: rotate(270deg);
  position: absolute;
  width: 100px;
  height: 40px;
  top: 15px;
  left: -35px;
}

.top__scores_bar p {
  font-family: "Oxygen Mono";
  font-size: 12px;
  text-transform: uppercase;
}

.top__scores_players {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

.top__play_text {
  width: auto;
  margin: 0.3em 0 0.5em 7%;
}

.top__play_text p {
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-family: "Oxygen Mono";
  font-weight: bold;
}

.top__scores_container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.top__scores_rankings {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
}

.top__scores_rankings p {
  margin: 0;
  padding: 0 0 3px 0;
  font-size: 10px;
  letter-spacing: 2px;
  font-family: "Oxygen Mono";
  text-overflow: ellipsis;
  white-space: nowrap;
}

.top__players {
  width: 70%;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 377px) {
  .top__scores_bar {
    min-width: 20px;
  }

  .top__scores_rankings p {
    margin: 0;
    padding: 2px;
    font-size: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .top__play_text p {
    padding: 0;
    margin: 0;
    font-size: 12px;
    font-family: "Oxygen Mono";
    font-weight: bold;
  }
}

@media only screen and (max-height: 730px) {
  .top__scores {
    top: -2px;
    left: -30px;
  }
  .top__scores_bar p {
    font-size: 10px;
  }

  .top__scores_rankings p {
    margin: 0;
    font-size: 8px;
    padding: 0;
    letter-spacing: 2px;
    font-family: "Oxygen Mono";
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .top__play_text p {
    padding: 0;
    margin: 0;
    font-size: 10px;
    font-family: "Oxygen Mono";
    font-weight: bold;
  }
}
