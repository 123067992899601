.GalleryBox__artists__container {
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  display: flex;
  align-items: flex-start;
  justify-content: right;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  height: calc(100% - 55px);
}

.GalleryBox__artists__container:hover {
  cursor: url("../../assets/images/arrowDown.svg") 10 30, auto;
}

::-webkit-scrollbar {
  display: none;
}
.GalleryBox__artist__wrapper {
  width: 100%;
  height: 99%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  border-bottom: 1px black solid;
  overflow-y: scroll;
}

.GalleryBox__artist {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 97%;
  height: 50%;
}

.GalleryBox__artist:nth-child(1) {
  border-bottom: 1px solid black;
}

.GalleryBox__artist_img {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 20%;
  height: 100%;
  margin-right: 1em;
}

.GalleryBox__artist_controls {
  display: flex;
  align-items: center;
}

.GalleryBox__artist_controls a:nth-child(1) {
  margin-right: 0.3em;
}

.GalleryBox__artist_controls a:nth-child(3) {
  margin-left: 0.3em;
}

.GalleryBox__artist_controls a > img {
  width: 8px;
}

.GalleryBox__artist_img span {
  font-family: "Aux Mono";
  font-size: 2em;
}

.GalleryBox__artist_img img {
  width: 139px;
}

.GalleryBox__artist_text {
  display: flex;
  flex-direction: column;
  width: 60%;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 2.5em;
}

.GalleryBox__artist_text p {
  margin: 0.1em 0 0em 0;
  font-size: 1.7em;
  font-weight: lighter;
  font-family: "Neue Montreal light";
}

.GalleryBox__artist_text p:nth-child(1) {
  margin-top: 0.4em;
  font-family: "Neue Montreal";
}

.GalleryBox__artist_links {
  height: 100%;
  width: 14%;
  margin-right: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.GalleryBox__artist_insta {
  margin-top: 1.4em;
}

.GalleryBox__artist_insta a > img {
  width: 23px;
}

.GalleryBox__artist_more a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-decoration: none;
  color: black;
}

.GalleryBox__artist_more {
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.GalleryBox__artist_more img {
  width: 40px;
  min-width: 20px;
}

.GalleryBox__artist_more p {
  font-family: "Oxygen Mono";
  width: 59%;
  font-size: 0.55em;
  white-space: nowrap;
  margin-right: 0.5em;
}

.GalleryBox__artist__scroll {
  height: 100%;
  width: 5px;
}
.GalleryBoxScroll__vertical_container {
  border-left: 1px solid black;
  width: 100%;
  height: 100%;
  position: relative;
}

.dot__vertical {
  position: absolute;
  background-color: black;
  width: 5px;
}

.dot__vertical:nth-child(1) {
  height: 15px;
}

.dot__vertical:nth-child(2) {
  height: 25px;
}

.dot__vertical:nth-child(3) {
  height: 20px;
}

.dot__vertical:nth-child(4) {
  height: 10px;
}

.dot__vertical:nth-child(5) {
  height: 28px;
}
/* WIDTH MEDIA QUERIES */

@media only screen and (max-width: 1050px) {
  .GalleryBox__artist_text p {
    font-size: 1.55em;
    margin-left: 0.5em;
  }
  .GalleryBox__artist_text p:nth-child(1) {
    margin-top: 0.5em;
  }
}

/* HEIGHT MEDIA QUERIES */

@media only screen and (max-height: 850px) {
  .GalleryBox__artist_img img {
    width: 120px;
  }

  .GalleryBox__artist_img span {
    font-size: 1.9em;
  }
  .GalleryBox__artist_more p {
    font-size: 9px;
  }

  .GalleryBox__artist_text p {
    font-size: 1.6em;
  }
  .GalleryBox__artist_text p:nth-child(1) {
    margin-top: 0.4em;
  }
}

@media only screen and (max-height: 780px) {
  .GalleryBox__artist_img img {
    width: 100px;
  }

  .GalleryBox__artist_text p:nth-child(1) {
    margin-top: 0.4em;
  }
}

@media only screen and (max-height: 730px) {
  .GalleryBox__artist_img img {
    width: 80px;
  }

  .GalleryBox__artist_text p {
    font-size: 1.3em;
  }

  .GalleryBox__artist_controls a > img {
    width: 6px;
  }

  .GalleryBox__artist_img span {
    font-size: 1.5em;
  }
  .GalleryBox__artist_more p {
    font-size: 9px;
  }
}

@media only screen and (max-height: 650px) {
  .GalleryBox__artist_img img {
    width: 70px;
  }

  .GalleryBox__artist_text p {
    font-size: 1.1em;
  }

  .GalleryBox__artist_controls a > img {
    width: 6px;
  }

  .GalleryBox__artist_img span {
    font-size: 1.3em;
  }
  .GalleryBox__artist_more p {
    font-size: 8px;
  }
}

/* WIDTH MEDIA QUERIES */
