.CustomForm__container {
  width: 100%;
  height: 100%;
  display: flex;
}
.CustomForm__wrapper {
  width: 90%;
  height: 100%;

  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
}

.CustomForm__form {
  border: 1px solid black;
  background: #ededed;
  margin-top: 1.5em;
  height: calc(100% - 140px);
}

.CustomForm__buttons_container {
  width: 90%;
  display: flex;
  position: relative;
  top: -25px;
  align-items: center;
  justify-content: space-between;
  margin-left: 2em;
}

.CustomForm__button_active {
  border: 1px solid black;
  position: relative;
  border-bottom: 0;
  height: 23px;
  border-radius: 10px 10px 0 0;
  padding: 0.1em 1em 0em 1em;
  background: #ededed;
  z-index: 2;
  font-size: 13px;
  font-family: "Cygnito Mono";
  color: #404040;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

.CustomForm__button {
  border: 1px solid black;
  font-family: "Cygnito Mono";
  border-bottom: 1px;
  font-size: 13px;

  z-index: 0;
  position: relative;
  height: 23px;
  border-radius: 10px 10px 0 0;
  padding: 0.1em 1em 0em 1em;
  background: linear-gradient(180deg, #ffffff 0%, #eaeaea 39.58%, #b9b9b9 100%);
  color: #404040;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

.CustomForm__form_container {
  margin: -0.5em 2em 0em 1em;
  width: 90%;
}

.CustomForm__form_wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.CustomForm__form_input_text {
  outline: none;
  z-index: 1;
  width: 40%;
  padding-left: 1em;
  font-size: 13px;
  height: 25px;
  color: #404040;
  font-family: "Cygnito Mono";
  border: 1.39483px solid #8e8e8e;
  box-shadow: inset 2.09225px -2.78967px 1.39483px #e9e9e9,
    inset 2.09225px 2.09225px 1.39483px #000000;
}

::placeholder {
  color: #333;
}

.CustomForm__form_select_container {
  margin-top: 1em;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.CustomForm__form_input_area {
  position: relative;
  outline: none;
  width: calc(100% - 1.1em);
  font-size: 13px;
  margin-top: 1em;
  height: 90px;
  padding: 0 0 0 1em;
  border: 1.39483px solid #8e8e8e;
  font-family: "Cygnito Mono";
  box-shadow: inset 2.09225px -2.78967px 1.39483px #e9e9e9,
    inset 2.09225px 2.09225px 1.39483px #000;
}

.CustomForm__form_submit_container {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 0.5em;
  z-index: 1;
}
.CustomForm_submit_container_2 {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 0.5em;
  position: relative;
}

.CustomForm__form_submit_button {
  border-left: 2px solid black;
  border-right: 4px solid black;
  border-top: 2px solid black;
  border-bottom: 3px solid black;
  width: 20%;
  height: 25px;
  font-size: 13px;
  font-family: "Cygnito Mono";
  box-shadow: inset 2.09225px -2.78967px 1.39483px gray,
    inset 2.09225px 2.09225px 1.39483px #000;
}

.profile__wrapper {
  display: flex;
  justify-content: space-between;
  width: 94%;
}

.profile__title {
  font-family: "Cygnito Mono";
  color: #404040;
  font-size: 12px;
  margin: 5px;
  letter-spacing: 2px;
  margin-bottom: 15px;
}

.profile__icon {
  position: relative;
  top: -0px;
  margin-left: 1em;
  width: 30px;
  height: 15px;
}
.CustomFrom__upload_wrapper {
  display: flex;
  width: 100%;
  justify-self: flex-end;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5em;
}
.CustomForm__upload_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45%;
  white-space: nowrap;
}

.CustomForm__upload_label {
  display: block;
  font-family: "Aux Mono";
  font-size: 11px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.file {
  display: block;
  opacity: 0;
  position: absolute;
  z-index: 1;
  width: 40%;
  left: 5%;
}

.CustomForm__input_url {
  width: 150px;
  height: 25px;
  font-size: 11px;
  outline: none;
  border: 1.39483px solid #8e8e8e;
  padding-left: 1.3em;
  font-family: "Cygnito Mono";
  box-shadow: inset 2.09225px -2.78967px 1.39483px #e9e9e9,
    inset 2.09225px 2.09225px 1.39483px #000;
}

.CustomForm__info__container {
  position: relative;
  width: 10%;
  height: 100%;
  z-index: 0;
}

.CustomForm_info {
  position: absolute;
  left: -192px;
  top: 25px;
  bottom: 0px;
  transform: rotate(270deg);
  width: 473px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 950px) {
  .CustomForm__wrapper {
    width: 100%;
  }

  .CustomForm__form_submit_container {
    margin-bottom: 0.5em;
  }

  .CustomForm__form_submit_button {
    margin-bottom: 1em;
  }
}

@media only screen and (max-width: 450px) {
  .CustomForm__input_url {
    width: 100px;
  }

  .CustomForm__form_submit_button {
    width: 60px;
  }
}

@media only screen and (max-width: 400px) {
  .CustomForm__buttons_container {
    margin-left: 0.5em;
  }
}

@media only screen and (max-height: 830px) {
  .CustomForm__form_input_area {
    height: 50px;
  }

  .CustomForm_submit_container_2 {
    margin-top: 0.5em;
  }
  .CustomFrom__upload_wrapper {
    margin-top: 0em;
  }

  .CustomForm__form_submit_container {
    margin-top: 1em;
  }

  .CustomForm_info {
    /* top: ; */
    width: 425px;
    /* bottom: 0; */
    left: -170px;
  }
}

@media only screen and (max-height: 780px) {
  .CustomForm__form_input_area {
    height: 50px;
  }

  .CustomForm_submit_container_2 {
    margin-top: 0.5em;
  }
  .CustomFrom__upload_wrapper {
    margin-top: 0em;
  }

  .CustomForm__form_submit_container {
    margin-top: 1em;
  }

  .CustomForm_info {
    /* top: 187px; */
    width: 381px;
    left: -150px;
  }
  .CustomForm__form {
    height: calc(100% - 125px);
    margin-top: 1.6em;
  }
}

@media only screen and (max-height: 730px) {
  .CustomForm__form_input_area {
    height: 30px;
  }

  .CustomForm__form {
    height: calc(100% - 125px);
    margin-top: 1.6em;
  }

  .CustomForm_info {
    /* top: 180px; */
    width: 351px;
    left: -135px;
  }
}

@media only screen and (max-height: 690px) {
  .CustomForm__form {
    height: calc(100% - 143px);
  }
  .CustomForm_submit_container_2 {
    margin-top: 0;
  }

  .CustomForm_info {
    /* top: 162px; */
    width: 316px;
    left: -125px;
  }
}

@media only screen and (max-height: 650px) {
  .CustomForm__location {
    height: 120px;
  }

  .CustomForm__form_input_area {
    height: 25px;
    font-size: 10px;
  }

  .CustomForm__button_active {
    height: 15px;
    font-size: 10px;
  }

  .CustomForm__button {
    height: 15px;
    font-size: 10px;
  }

  .CustomForm__buttons_container {
    top: -19px;
  }

  .CustomForm__form {
    height: calc(100% - 125px);
    margin-top: 0.3em;
  }

  .CustomForm__form_input_text {
    height: 15px;
    font-size: 11px;
  }

  .CustomForm__input_url {
    height: 15px;
    font-size: 11px;
  }
  .CustomForm__form_submit_button {
    width: 20%;
    height: 20px;
    font-size: 11px;
  }

  .CustomForm_info {
    /* top: 135px; */
    top: 7px;
    width: 304px;
    left: -100px;
  }
}
