.ItoStudio__container {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-image: url("../../assets/images/tatoo-machine.svg");
  background-repeat: no-repeat;
  background-position: 100% 100%;
}

.ItoStudio__wrapper {
  display: flex;
  flex-direction: column;
  height: calc(100% - 118px);
  width: 100%;
}

.ItoStudio__Header__container {
  height: 260px;
  display: flex;
  align-items: flex-end;
}

.ItoStudio__content_wrapper {
  height: calc(100% - 260px);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ItoStudio__left__container {
  width: 47%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 496px;
}

.ItoStudio__right__container {
  width: 51%;
  height: 496px;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.ItoStudio__subtitle__container {
  position: relative;
  width: 100%;
}

.ItoStudio__subtitle__container2 p,
.ItoStudio__subtitle__container p {
  font-family: "Neue Montreal";
  font-size: 32px;
  font-weight: bold;
  white-space: nowrap;
  letter-spacing: 4px;
  word-spacing: 6px;
  text-align: start;
  margin: 0 0 0 0px;
}

.ItoStudio__subtitle__container2 p {
  background-color: black;
  color: white;
  width: fit-content;
}

.ItoStudio__subtitle__container a {
  text-decoration: none;
}
.ItoStudio__subtitle__box {
  background-color: black;
  color: white;
}

.ItoStudio__subtitle__anchor {
  font-weight: lighter;
  font-family: "Neue Montreal light";
}

.ItoStudio__text__container {
  display: flex;
  width: 100%;
  align-items: flex-start;
  height: 100%;
  justify-content: space-between;
}

.ItoStudio__text__left {
  width: 48%;
}

.ItoStudio__text__left p {
  font-family: "Oxygen Mono";
  font-size: 15px;
  text-align: justify;
  margin-top: 1.5em;
  margin-bottom: 0;
  line-height: 1.1;
}

.ItoStudio__text__left span {
  font-family: "Aux Mono";
  font-weight: bold;
}

.ItoStudio__text__right {
  width: 48%;
}

.ItoStudio__text__right p {
  font-family: "Oxygen Mono";
  font-size: 15px;
  text-align: justify;
  margin-top: 1.5em;
  margin-bottom: 0;
  line-height: 1.1;
}
.ItoStudio__text__right span {
  font-family: "Aux Mono";
  font-weight: bold;
}

.ItoStudio__portal__container {
  position: relative;
  height: 442px;
  margin-top: 1em;
  width: 100%;
}

.ItoStudio__portal__wrapper {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-self: center;
  background: transparent;
}

/* WIDTH MEDIA QUERIES */

@media only screen and (max-width: 1290px) {
  .ItoStudio__text__left p {
    font-size: 14px;
  }
  .ItoStudio__subtitle__container2 p,
  .ItoStudio__subtitle__container p {
    font-size: 28px;
  }
  .ItoStudio__subtitle__container img {
    width: 180px;
  }
}

@media only screen and (max-width: 1245px) {
  .ItoStudio__subtitle__container2 p,
  .ItoStudio__subtitle__container p {
    font-size: 26px;
  }
  .ItoStudio__subtitle__container img {
    width: 170px;
  }
}

@media only screen and (max-width: 1170px) {
  .ItoStudio__subtitle__container2 p,
  .ItoStudio__subtitle__container p {
    font-size: 24px;
  }
  .ItoStudio__subtitle__container img {
    width: 160px;
  }
}

@media only screen and (max-width: 1110px) {
  .ItoStudio__subtitle__container2 p,
  .ItoStudio__subtitle__container p {
    font-size: 22px;
  }
  .ItoStudio__subtitle__container img {
    width: 150px;
  }
}

@media only screen and (max-width: 1035px) {
  .ItoStudio__subtitle__container2 p,
  .ItoStudio__subtitle__container p {
    font-size: 20px;
  }
  .ItoStudio__subtitle__container img {
    width: 140px;
  }
}

@media only screen and (max-width: 976px) {
  .ItoStudio__subtitle__container2 p,
  .ItoStudio__subtitle__container p {
    font-size: 18px;
  }
  .ItoStudio__subtitle__container img {
    width: 130px;
  }
}

/* HEIGHT MEDIA QUERIES */

@media only screen and (max-height: 920px) {
  .ItoStudio__Header__container {
    height: 235px;
  }

  .ItoStudio__content_wrapper {
    height: calc(100% - 235px);
  }
}

@media only screen and (max-height: 900px) {
  .ItoStudio__Header__container {
    height: 205px;
  }

  .ItoStudio__content_wrapper {
    height: calc(100% - 205px);
  }

  .ItoStudio__wrapper {
    height: calc(100% - 100px);
  }
}

@media only screen and (max-height: 830px) {
  .ItoStudio__Header__container {
    height: 205px;
  }

  .ItoStudio__content_wrapper {
    height: calc(100% - 205px);
  }

  .ItoStudio__wrapper {
    height: calc(100% - 100px);
  }

  .ItoStudio__left__container {
    height: 442px;
  }

  .ItoStudio__right__container {
    height: 442px;
  }

  .ItoStudio__text__left p,
  .ItoStudio__text__right p {
    margin-top: 1em;
    margin-bottom: 0;
    font-size: 13px;
  }
  .ItoStudio__subtitle__container2 p,
  .ItoStudio__subtitle__container p {
    font-size: 24px;
  }
  .ItoStudio__subtitle__container img {
    width: 160px;
  }

  .ItoStudio__portal__container {
    height: 400px;
    min-height: 400px;
  }
}

@media only screen and (max-height: 780px) {
  .ItoStudio__wrapper {
    height: calc(100% - 80px);
  }

  .ItoStudio__left__container {
    height: 405px;
  }

  .ItoStudio__right__container {
    height: 405px;
  }

  .ItoStudio__text__left p,
  .ItoStudio__text__right p {
    font-size: 12px;
  }
  .ItoStudio__subtitle__container2 p,
  .ItoStudio__subtitle__container p {
    font-size: 20px;
  }
  .ItoStudio__subtitle__container img {
    width: 140px;
  }
  .ItoStudio__portal__container {
    height: 365px;
    min-height: 365px;
  }
}

@media only screen and (max-height: 730px) {
  .ItoStudio__Header__container {
    height: 180px;
  }

  .ItoStudio__content_wrapper {
    height: calc(100% - 180px);
  }

  .ItoStudio__wrapper {
    height: calc(100% - 80px);
  }

  .ItoStudio__left__container {
    height: 340px;
  }

  .ItoStudio__right__container {
    height: 340px;
  }

  .ItoStudio__text__left p,
  .ItoStudio__text__right p {
    font-size: 11px;
  }
  .ItoStudio__subtitle__container2 p,
  .ItoStudio__subtitle__container p {
    font-size: 18px;
  }
  .ItoStudio__subtitle__container img {
    width: 130px;
  }
  .ItoStudio__portal__container {
    height: 302px;
    min-height: 302px;
  }
}

@media only screen and (max-height: 650px) {
  .ItoStudio__Header__container {
    height: 180px;
  }

  .ItoStudio__content_wrapper {
    height: calc(100% - 180px);
  }

  .ItoStudio__wrapper {
    height: calc(100% - 70px);
  }

  .ItoStudio__text__left p,
  .ItoStudio__text__right p {
    font-size: 10px;
  }

  .ItoStudio__left__container {
    height: 306px;
  }

  .ItoStudio__right__container {
    height: 306px;
  }
  .ItoStudio__subtitle__container2 p,
  .ItoStudio__subtitle__container p {
    font-size: 16px;
  }
  .ItoStudio__subtitle__container img {
    width: 120px;
  }
  .ItoStudio__portal__container {
    height: 270px;
    min-height: 270px;
  }
}
