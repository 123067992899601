.Gallery__container {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.Gallery__wrapper {
  display: flex;
  flex-direction: column;
  height: calc(100% - 118px);
  width: 100%;
}

.Gallery__Header__container {
  height: 260px;
  display: flex;
  align-items: flex-end;
}

.Gallery__content_wrapper {
  height: calc(100% - 260px);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Gallery__left__container {
  width: 41%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 496px;
}

.Gallery__right__container {
  width: 55%;
  height: 496px;
  display: flex;
  flex-direction: column;
  justify-content: start;
}
.Gallery__subtitle__container2,
.Gallery__subtitle__container {
  width: 100%;
  position: relative;
}

.Gallery__subtitle__container img {
  /* position: absolute; */
  width: 420px;
}

.Gallery__subtitle__container2 p,
.Gallery__subtitle__container p {
  font-family: "Neue Montreal";
  font-size: 32px;
  font-weight: bold;
  white-space: nowrap;
  letter-spacing: 4px;
  word-spacing: 6px;
  text-align: start;
  margin: 0 0 0 10px;
}

.Gallery__subtitle__container2 p {
  margin: 0;
}

.Gallery__subtitle__container a {
  text-decoration: none;
}

.Gallery__subtitle__boder {
  background: url("../../assets/images/border-text.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 0px 5px 0px 8px;
}

.Gallery__subtitle__box {
  background-color: black;
  color: white;
}

.Gallery__subtitle__anchor {
  font-family: "Neue Montreal light";
  font-weight: lighter;
}

.Gallery__text__container {
  display: flex;
  width: 100%;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  flex-direction: column;
}

.Gallery__text__left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Gallery__text__left p {
  text-align: justify;
  font-family: "Oxygen Mono";
  font-size: 15px;
  margin-top: 1em;
  margin-bottom: 0;
  line-height: 1.1;
}

.Gallery__portal__container {
  width: 100%;
  height: 442px;
  margin-top: 1em;
}

.Gallery__portal__wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: center;
  background: transparent;
}

/* WIDTH MEDIA QUERIES */

@media only screen and (max-width: 1290px) {
  .Gallery__text__left p {
    font-size: 14px;
  }
  .Gallery__subtitle__container2 p,
  .Gallery__subtitle__container p {
    font-size: 28px;
    margin-bottom: 0.2em;
  }
  .Gallery__subtitle__container img {
    width: 180px;
  }
}

@media only screen and (max-width: 1245px) {
  .Gallery__subtitle__container2 p,
  .Gallery__subtitle__container p {
    font-size: 26px;
    margin-bottom: 0.3em;
  }
  .Gallery__subtitle__container img {
    width: 170px;
  }
}

@media only screen and (max-width: 1170px) {
  .Gallery__subtitle__container2 p,
  .Gallery__subtitle__container p {
    font-size: 24px;
    margin-bottom: 0.4em;
  }
  .Gallery__subtitle__container img {
    width: 160px;
  }
}

@media only screen and (max-width: 1110px) {
  .Gallery__subtitle__container2 p,
  .Gallery__subtitle__container p {
    font-size: 22px;
    margin-bottom: 0.5em;
  }
  .Gallery__subtitle__container img {
    width: 150px;
  }
}

@media only screen and (max-width: 1035px) {
  .Gallery__subtitle__container2 p,
  .Gallery__subtitle__container p {
    font-size: 20px;
    margin-bottom: 0.6em;
  }
  .Gallery__subtitle__container img {
    width: 140px;
  }
}

@media only screen and (max-width: 976px) {
  .Gallery__subtitle__container2 p,
  .Gallery__subtitle__container p {
    font-size: 18px;
    margin-bottom: 0.9em;
  }
  .Gallery__subtitle__container img {
    width: 130px;
  }
}

/* HEIGHT MEDIA QUERIES */

@media only screen and (max-height: 920px) {
  .Gallery__Header__container {
    height: 235px;
  }

  .Gallery__content_wrapper {
    height: calc(100% - 235px);
  }
}

@media only screen and (max-height: 900px) {
  .Gallery__Header__container {
    height: 205px;
  }

  .Gallery__content_wrapper {
    height: calc(100% - 205px);
  }

  .Gallery__wrapper {
    height: calc(100% - 100px);
  }
}

@media only screen and (max-height: 830px) {
  .Gallery__Header__container {
    height: 205px;
  }

  .Gallery__content_wrapper {
    height: calc(100% - 205px);
  }

  .Gallery__wrapper {
    height: calc(100% - 100px);
  }

  .Gallery__left__container {
    height: 442px;
  }

  .Gallery__right__container {
    height: 442px;
  }

  .Gallery__text__left p {
    margin-top: 1em;
    margin-bottom: 0;
    font-size: 13px;
  }
  .Gallery__subtitle__container2 p,
  .Gallery__subtitle__container p {
    font-size: 24px;
  }
  .Gallery__subtitle__container img {
    width: 330px;
  }

  .Gallery__portal__container {
    height: 400px;
  }
}

@media only screen and (max-height: 780px) {
  .Gallery__wrapper {
    height: calc(100% - 80px);
  }

  .Gallery__left__container {
    height: 405px;
  }

  .Gallery__right__container {
    height: 405px;
  }

  .Gallery__text__left p {
    font-size: 12px;
  }
  .Gallery__subtitle__container2 p,
  .Gallery__subtitle__container p {
    font-size: 20px;
  }
  .Gallery__subtitle__container img {
    width: 270px;
  }
  .Gallery__portal__container {
    height: 365px;
  }
}

@media only screen and (max-height: 730px) {
  .Gallery__Header__container {
    height: 180px;
  }

  .Gallery__content_wrapper {
    height: calc(100% - 180px);
  }

  .Gallery__wrapper {
    height: calc(100% - 80px);
  }

  .Gallery__left__container {
    height: 340px;
  }

  .Gallery__right__container {
    height: 340px;
  }

  .Gallery__text__left p {
    font-size: 11px;
  }
  .Gallery__subtitle__container2 p,
  .Gallery__subtitle__container p {
    font-size: 18px;
  }
  .Gallery__subtitle__container img {
    width: 250px;
  }
  .Gallery__portal__container {
    height: 305px;
  }
}

@media only screen and (max-height: 650px) {
  .Gallery__Header__container {
    height: 180px;
  }

  .Gallery__content_wrapper {
    height: calc(100% - 180px);
  }

  .Gallery__wrapper {
    height: calc(100% - 70px);
  }

  .Gallery__text__left p {
    font-size: 10px;
  }

  .Gallery__left__container {
    height: 306px;
  }

  .Gallery__right__container {
    height: 306px;
  }
  .Gallery__subtitle__container2 p,
  .Gallery__subtitle__container p {
    font-size: 16px;
  }
  .Gallery__subtitle__container img {
    width: 200px;
  }
  .Gallery__portal__container {
    height: 270px;
  }
}
