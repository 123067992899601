.Header__wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
}

.Header__title_wrapper {
  display: flex;
}

.Header__title {
  font-family: "Aux Mono";
  font-size: 31px;
  letter-spacing: 2px;
}

.Header__title_border {
  width: 150px;
  font-family: "Aux Mono";
  font-size: 31px;
  letter-spacing: 7px;
  background: url("../../assets/images/border-text.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 0px 0px 0px 0px;
  margin-left: 0.2em;
  white-space: nowrap;
  position: relative;
  text-align: center;
}

.Header__phrase {
  font-family: "Oxygen Mono";
  margin-left: 3em;
  font-size: 15px;
}

.Header__horizontal_bar {
  height: 6px;
  width: 100%;
  border: 1px solid black;
  margin-top: 1em;
  margin-bottom: 1em;
}

.Header__title_photo {
  height: 100px;
  width: 150px;
}

.Header__title_photo img {
  width: 100%;
  height: 100%;
}

.Header__title_photo_talk {
  width: 200px;
}
.Header__title_photo_talk img {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 1290px) {
  .Header__phrase {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1150px) {
  .Header__phrase {
    margin-left: 3em;
  }
}
@media only screen and (max-width: 1144px) {
  .Header__title_photo {
    height: 90px;
    width: 130px;
  }
}

@media only screen and (max-width: 1120px) {
  .Header__phrase {
    font-size: 13px;
  }
}
@media only screen and (max-width: 1118px) {
  .Header__title_photo {
    height: 80px;
    width: 110px;
  }
}
@media only screen and (max-width: 1100px) {
  .Header__title {
    font-size: 29px;
    letter-spacing: 2px;
  }

  .Header__title_border {
    font-size: 29px;
    letter-spacing: 6.8px;
    margin-left: 0.5em;
  }
}
@media only screen and (max-width: 1025px) {
  .Header__phrase {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1000px) {
  .Header__title {
    font-size: 28px;
    letter-spacing: 2px;
  }

  .Header__title_border {
    font-size: 28px;
    letter-spacing: 6.8px;
    margin-left: 0.5em;
  }
}

@media only screen and (max-width: 900px) {
  .Header__title {
    font-size: 27px;
    letter-spacing: 2px;
  }

  .Header__title_border {
    font-size: 27px;
    letter-spacing: 6.8px;
    margin-left: 0.5em;
  }

  .Header__phrase {
    margin-left: 2em;
  }
}

@media only screen and (max-width: 850px) {
  .Header__wrapper {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
    /* margin-bottom: 1em; */
  }
  .Header__phrase {
    margin: 0;
  }
  .Header__title_photo {
    height: auto;
    margin-bottom: 0.5em;
    width: 150px;
  }
}

@media only screen and (max-width: 635px) {
}

@media only screen and (max-width: 480px) {
  .Header__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }
  .Header__phrase {
    margin-left: 0;
  }
  .Header__title {
    font-size: 25px;
    letter-spacing: 2px;
  }

  .Header__title_border {
    font-size: 25px;
    letter-spacing: 6.8px;
    margin-left: 0.5em;
  }
  .Header__horizontal_bar {
    height: 6px;
    width: 100%;
    border: 1px solid black;
    margin-top: 2em;
    margin-bottom: 1em;
  }
}

@media only screen and (max-width: 400px) {
  .Header__title_border span {
    padding: 6px;
    position: relative;
    top: 0px;
  }
}

/* HEIGHT MEDIA QUERIES */

@media only screen and (max-height: 830px) {
  .Header__phrase {
    font-size: 13px;
  }
}

@media only screen and (max-height: 780px) {
  .Header__phrase {
    font-size: 12px;
  }
}

@media only screen and (max-height: 730px) {
  .Header__phrase {
    font-size: 11px;
  }
}

@media only screen and (max-height: 650px) {
  .Header__phrase {
    font-size: 10px;
  }
}
