.GridAbout__container {
  border: 1px solid black;
  width: 100%;
  height: 100%;
  display: flex;
}

.GridAbout__left_container,
.GridAbout__right_container {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.GridAbout__right_container {
  border-left: 1px solid black;
}

.GridAbout__item {
  height: calc(100% / 3);
  width: 100%;
  border-bottom: 1px solid black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.GridAbout__item_dark {
  height: calc(100% / 3);
  width: 100%;
  background-color: black;
  border-bottom: 1px solid white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.GridAbout__item:last-child {
  border-bottom: 0;
}

.GridAbout__item_text {
  height: 80%;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.GridAbout__item_text_dark {
  height: 80%;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.GridAbout__item_text_dark p {
  font-family: "Oxygen Mono";
  text-align: justify;
  font-size: 15px;
  margin: 0;
  color: white;
}

.GridAbout__item_text_dark span {
  font-family: "Aux Mono";
  text-align: justify;
  font-size: 25px;
  color: white;
}

.GridAbout__item_text span {
  font-family: "Aux Mono";
  text-align: justify;
  font-size: 25px;
}

.GridAbout__item_text p {
  font-family: "Oxygen Mono";
  text-align: justify;
  font-size: 15px;
  margin: 0;
}

.GridAbout__item_link {
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.GridAbout__item_link_dark {
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.GridAbout__item_link_dark a {
  text-decoration: none;
  color: white;
  margin-right: 0.5em;
  font-size: 10px;
  font-family: "Oxygen Mono";
}

.GridAbout__item_link_dark img {
  margin-right: 1em;
  filter: invert(100%);
}

.GridAbout__item_link a {
  text-decoration: none;
  color: black;
  margin-right: 0.5em;
  font-size: 10px;
  font-family: "Oxygen Mono";
}

.GridAbout__item_link img {
  margin-right: 1em;
}

.GridAbout__subitem_top {
  height: 60%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid black;
}

.GridAbout__subitem_top img {
  width: 300px;
  object-fit: contain;
}

.GridAbout__subitem_top_dark {
  height: 60%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid white;
}

.GridAbout__subitem_top_dark img {
  width: 300px;
  filter: invert(100%);
  object-fit: contain;
}

.GridAbout__subitem_bottom {
  height: 40%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.GridAbout__subitem_bottom_left {
  width: 30%;
  height: 100%;
  border-right: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.GridAbout__subitem_bottom_left img {
  width: 45px;
}

.GridAbout__subitem_bottom_left_dark {
  width: 30%;
  height: 100%;
  border-right: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.GridAbout__subitem_bottom_left_dark img {
  width: 45px;
  filter: invert(100%);
}

.GridAbout__subitem_bottom_right {
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.GridAbout__subitem_bottom_right p {
  margin: 0;
  font-family: "Oxygen Mono";
  font-size: 1.4em;
  font-weight: 500;
  letter-spacing: 2px;
}

.GridAbout__subitem_bottom_right_dark {
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.GridAbout__subitem_bottom_right_dark p {
  margin: 0;
  font-family: "Oxygen Mono";
  font-size: 1.4em;
  font-weight: 500;
  letter-spacing: 2px;
  color: white;
}

/* WIDTH MEDIA QUERIES */

@media only screen and (max-width: 1600px) {
  .GridAbout__subitem_top img,
  .GridAbout__subitem_top_dark img {
    width: 280px;
  }
}

@media only screen and (max-width: 1490px) {
  .GridAbout__subitem_top img,
  .GridAbout__subitem_top_dark img {
    width: 260px;
  }

  .GridAbout__subitem_bottom_left img,
  .GridAbout__subitem_bottom_left_dark img {
    width: 43px;
  }

  .GridAbout__subitem_bottom_right p,
  .GridAbout__subitem_bottom_right_dark p {
    font-size: 1.3em;
  }
}

@media only screen and (max-width: 1360px) {
  .GridAbout__subitem_top img,
  .GridAbout__subitem_top_dark img {
    width: 240px;
  }

  .GridAbout__subitem_bottom_left img,
  .GridAbout__subitem_bottom_left_dark img {
    width: 40px;
  }

  .GridAbout__subitem_bottom_right p,
  .GridAbout__subitem_bottom_right_dark p {
    font-size: 1.2em;
  }

  .GridAbout__item_text p,
  .GridAbout__item_text_dark p {
    font-size: 15px;
  }

  .GridAbout__item_link a,
  .GridAbout__item_link_dark a {
    font-size: 10px;
  }
}

@media only screen and (max-width: 1270px) {
  .GridAbout__subitem_top img,
  .GridAbout__subitem_top_dark img {
    width: 220px;
  }

  .GridAbout__subitem_bottom_left img,
  .GridAbout__subitem_bottom_left_dark img {
    width: 38px;
  }

  .GridAbout__subitem_bottom_right p,
  .GridAbout__subitem_bottom_right_dark p {
    font-size: 1.1em;
  }

  .GridAbout__item_text p,
  .GridAbout__item_text_dark p {
    font-size: 14px;
  }

  .GridAbout__item_text_dark span {
    font-size: 24px;
  }

  .GridAbout__item_text span {
    font-size: 24px;
  }

  .GridAbout__item_link a,
  .GridAbout__item_link_dark a {
    font-size: 9px;
  }
}

@media only screen and (max-width: 1180px) {
  .GridAbout__subitem_top img,
  .GridAbout__subitem_top_dark img {
    width: 200px;
  }

  .GridAbout__subitem_bottom_left img,
  .GridAbout__subitem_bottom_left_dark img {
    width: 34px;
  }

  .GridAbout__subitem_bottom_right p,
  .GridAbout__subitem_bottom_right_dark p {
    font-size: 1em;
  }
}

@media only screen and (max-width: 1080px) {
  .GridAbout__subitem_top img,
  .GridAbout__subitem_top_dark img {
    width: 180px;
  }

  .GridAbout__subitem_bottom_left img,
  .GridAbout__subitem_bottom_left_dark img {
    width: 30px;
  }

  .GridAbout__subitem_bottom_right p,
  .GridAbout__subitem_bottom_right_dark p {
    font-size: 0.9em;
  }

  .GridAbout__item_text p,
  .GridAbout__item_text_dark p {
    font-size: 13px;
  }
  .GridAbout__item_text_dark span {
    font-size: 22px;
  }

  .GridAbout__item_text span {
    font-size: 22px;
  }
}

@media only screen and (max-width: 1010px) {
  .GridAbout__subitem_top img,
  .GridAbout__subitem_top_dark img {
    width: 170px;
  }

  .GridAbout__subitem_bottom_left img,
  .GridAbout__subitem_bottom_left_dark img {
    width: 28px;
  }

  .GridAbout__subitem_bottom_right p,
  .GridAbout__subitem_bottom_right_dark p {
    font-size: 0.85em;
  }

  .GridAbout__item_text p,
  .GridAbout__item_text_dark p {
    font-size: 12px;
  }
}

/* HEIGHT MEDIA QUERIES */

@media only screen and (max-height: 830px) {
  .GridAbout__item_text p,
  .GridAbout__item_text_dark p {
    font-size: 13px;
  }

  .GridAbout__item_link a,
  .GridAbout__item_text_dark a {
    font-size: 10px;
  }

  .GridAbout__item_text_dark span {
    font-size: 23px;
  }

  .GridAbout__item_text span {
    font-size: 23px;
  }
}

@media only screen and (max-height: 780px) {
  .GridAbout__item_text p,
  .GridAbout__item_text_dark p {
    font-size: 12px;
  }
  .GridAbout__item_link a,
  .GridAbout__item_text_dark a {
    font-size: 8px;
  }
  .GridAbout__item_text_dark span {
    font-size: 22px;
  }

  .GridAbout__item_text span {
    font-size: 22px;
  }
}

@media only screen and (max-height: 730px) {
  .GridAbout__item_text p,
  .GridAbout__item_text_dark p {
    font-size: 11px;
  }
  .GridAbout__item_link a,
  .GridAbout__item_text_dark a {
    font-size: 8px;
  }
  .GridAbout__item_text_dark span {
    font-size: 21px;
  }

  .GridAbout__item_text span {
    font-size: 21px;
  }
}

@media only screen and (max-height: 650px) {
  .GridAbout__item_text p,
  .GridAbout__item_text_dark p {
    font-size: 10px;
  }
  .GridAbout__item_link a,
  .GridAbout__item_text_dark a {
    font-size: 7px;
  }
  .GridAbout__item_text_dark span {
    font-size: 20px;
  }

  .GridAbout__item_text span {
    font-size: 20px;
  }
}
