.MineSweeper__wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.MineSweeper__container {
  z-index: 1;
  position: relative;
  margin-top: 1.7em;
  margin-bottom: 2em;
}

.MineSweeper__scoreboard_container {
  width: 99%;
  height: 103px;
}

@media only screen and (max-height: 730px) {
  .MineSweeper__container {
    margin-bottom: 1em;
  }
  .MineSweeper__scoreboard_container {
    height: 80px;
  }
}

@media only screen and (max-height: 650px) {
  .MineSweeper__container {
    margin-top: 0.3em;
  }
  .MineSweeper__scoreboard_container {
    height: 80px;
  }
}
