.MainBigSize__wrapper {
  width: 500vw;
  height: 100vh;
  display: flex;
}

.MainBigSize__stickyBars {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100vw;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}

.MainBigSize__stickyBar_top_container {
  width: 80%;
  margin-top: 255px;
}

.MainBigSize__stickyBar_top {
  border: 1px solid black;
  height: 5px;
  width: 100%;
}

.MainBigSize__stickyBar_bottom_container {
  width: 80%;
  /* margin-bottom: 2.5em; */
  margin-bottom: 98px;
}

.MainBigSize__Screens__wrapper {
  width: 100vw;
  background: transparent;
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-height: 920px) {
  .MainBigSize__stickyBar_top_container {
    margin-top: 230px;
  }

  .MainBigSize__stickyBar_bottom_container {
    margin-bottom: 98px;
  }
}

@media only screen and (max-height: 900px) {
  .MainBigSize__stickyBar_top_container {
    margin-top: 200px;
  }

  .MainBigSize__stickyBar_bottom_container {
    margin-bottom: 70px;
  }
}

@media only screen and (max-height: 780px) {
  .MainBigSize__stickyBar_bottom_container {
    margin-bottom: 50px;
  }
}

@media only screen and (max-height: 730px) {
  .MainBigSize__stickyBar_top_container {
    margin-top: 180px;
  }

  .MainBigSize__stickyBar_bottom_container {
    margin-bottom: 50px;
  }
}

@media only screen and (max-height: 650px) {
  .MainBigSize__stickyBar_top_container {
    margin-top: 170px;
  }

  .MainBigSize__stickyBar_bottom_container {
    margin-bottom: 40px;
  }
}
