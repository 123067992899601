.menu__link {
  color: black;
  text-decoration: none;
  font-size: 7px;
  font-family: "Oxygen Mono";
  cursor: pointer;
  width: 200px;
  max-width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
}

.menu__link:hover {
  color: white;
}

@media only screen and (min-width: 375px) {
  .menu__link {
    font-size: 8px;
  }
}

@media only screen and (min-width: 768px) {
  .menu__link {
    width: 250px;
    font-size: 11px;
    height: 42px;
  }
}

.menu__container {
  background: #ededed;
  border: 2px solid #8e8e8e;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 116px;
  max-width: 100%;
  height: 360px;
  display: flex;
  font-size: 9px;
  font-family: "Oxygen Mono";
  z-index: 3;
}

@media only screen and (min-width: 375px) {
  .menu__container {
    width: 120px;
  }
}

@media only screen and (min-width: 768px) {
  .menu__container {
    width: 250px;
    font-size: 11px;
    height: 480px;
    border: 3px solid #8e8e8e;
  }
}

.menu__sidebar {
  height: 100%;
  width: 50px;
  max-width: 100%;
  display: none;
  border-right: 2px solid #8e8e8e;
}

@media only screen and (min-width: 768px) {
  .menu__sidebar {
    display: block;
    border-right: 3px solid #8e8e8e;
  }
}

.menu__navbar {
  width: 200px;
  max-width: 100%;
  height: 100%;
}

.menu__navbar_border {
  position: absolute;
  z-index: -1;
  width: 307px;
  height: 577px;
  right: -4px;
  bottom: -4px;
  border-right: 2px solid black;
  border-bottom: 2px solid black;
}

@media only screen and (min-width: 768px) {
  .menu__navbar_border {
    right: -5px;
    bottom: -5px;
  }
}

.menu__wrapper_home {
  width: 200px;
  max-width: 100%;
  height: 60px;
  border-bottom: 1px solid #8e8e8e;
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 768px) {
  .menu__wrapper_home {
    height: 75px;
  }
}

.menu__home {
  width: 200px;
  max-width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (min-width: 768px) {
  .menu__home {
    height: 42px;
  }
}

.menu__home:hover {
  background-color: black;
  color: white;
  cursor: pointer;
}

.menu__home:hover .menu__link {
  color: white;
}

.menu__pages div {
  width: 200px;
  max-width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 768px) {
  .menu__pages div {
    height: 75px;
  }
}

.menu__pages_border {
  position: absolute;
  z-index: -1;
  width: 114px;
  height: 577px;
  right: -4px;
  bottom: -4px;
  border-right: 2px solid black;
  border-bottom: 2px solid black;
}

@media only screen and (min-width: 375px) {
  .menu__pages_border {
    width: 121px;
  }
}

@media only screen and (min-width: 768px) {
  .menu__pages_border {
    width: 203px;
    right: -5px;
    bottom: -5px;
  }
}

.subMenu__transparent {
  width: 204px;
  max-width: 100%;
  height: 100%;
  position: absolute;
  top: -2px;
  right: -122px;
  border: 3px solid transparent;
  background: transparent;
  font-size: 10px;
  z-index: -1;
  display: none;
}

@media only screen and (min-width: 375px) {
  .subMenu__transparent {
    right: -125px;
  }
}

@media only screen and (min-width: 768px) {
  .subMenu__transparent {
    right: -210px;
  }
}

.menu__pages .menu__aboutUs,
.menu__pages .menu__ito3dsgn,
.menu__pages .menu__itoGallery,
.menu__pages .menu__itoStudio {
  height: 32px;
  width: 200px;
  max-width: 100%;
  display: flex;
  justify-content: flex-start;
}

@media only screen and (min-width: 768px) {
  .menu__pages .menu__aboutUs,
  .menu__pages .menu__ito3dsgn,
  .menu__pages .menu__itoGallery,
  .menu__pages .menu__itoStudio {
    height: 42px;
  }
}

.menu__aboutUs .submenu__blog {
  display: none;
}

.menu__aboutUs:hover .submenu__blog {
  display: block;
}

.menu__aboutUs:hover .subMenu__transparent {
  display: block;
}

.menu__aboutUs:hover {
  background-color: black;
  color: white;
  cursor: pointer;
}

.menu__aboutUs:hover .menu__link {
  background-color: black;
  color: white;
}

.menu__ito3dsgn .submenu {
  display: none;
}

.menu__ito3dsgn:hover .submenu,
.menu__ito3dsgn:hover .subMenu__transparent {
  display: block;
}

.menu__ito3dsgn:hover {
  background-color: black;
  color: white;
  cursor: pointer;
}

.menu__ito3dsgn:hover .menu__link {
  background-color: black;
  color: white;
}

.menu__itoGallery .submenu {
  display: none;
}

.menu__itoGallery:hover .submenu,
.menu__itoGallery:hover .subMenu__transparent {
  display: block;
}

.menu__itoGallery:hover {
  background-color: black;
  color: white;
  cursor: pointer;
}

.menu__itoGallery:hover .menu__link {
  background-color: black;
  color: white;
}

.subMenu__title_gallery {
  font-size: 7px;
  text-decoration: none;
}
@media only screen and (min-width: 375px) {
  .subMenu__title_gallery {
    font-size: 8px;
  }
}

@media only screen and (min-width: 768px) {
  .subMenu__title_gallery {
    font-size: 11px;
  }
}

.menu__icon_NFT {
  height: 8px;
  width: 6px;
  margin-right: 10px;
}

@media only screen and (min-width: 768px) {
  .menu__icon_NFT {
    height: 13.33px;
    width: 10px;
    margin-right: 15px;
  }
}

.subMenu__title_events {
  font-size: 7px;
  margin-left: 10px;
}

@media only screen and (min-width: 375px) {
  .subMenu__title_events {
    font-size: 8px;
  }
}

@media only screen and (min-width: 768px) {
  .subMenu__title_events {
    font-size: 11px;
    margin-left: 25px;
  }
}

.menu__itoStudio .submenu {
  display: none;
}

.menu__itoStudio:hover .submenu,
.menu__itoStudio:hover .subMenu__transparent {
  display: block;
}

.menu__itoStudio:hover {
  background-color: black;
  color: white;
  cursor: pointer;
}

.menu__itoStudio:hover .menu__link {
  color: white;
  background-color: black;
}

.menu__pages .menu__letsTalk {
  height: 32px;
  width: 200px;
  margin-top: -10px;
  max-width: 100%;
  margin-bottom: 5px;
}

@media only screen and (min-width: 768px) {
  .menu__pages .menu__letsTalk {
    height: 42px;
  }
}

.menu__letsTalk:hover {
  background-color: black;
  color: white;
  cursor: pointer;
}

.menu__letsTalk:hover .menu__link {
  color: white;
}

.menu__page_lets {
  margin-right: 15px;
  font-size: 9px;
  margin-left: 10px;
}

@media only screen and (min-width: 768px) {
  .menu__page_lets {
    margin-right: 50px;
    font-size: 11px;
    margin-left: 0px;
  }
}

.navbar__folders a:hover {
  background: url("../../assets/images/folder-gray.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.navbar__folders a:active {
  background: url("../../assets/images/folder-black.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.menu__home_icon {
  width: 19px;

  height: 12.5px;
  margin-left: 12px;
}

@media only screen and (min-width: 768px) {
  .menu__home_icon {
    width: 32px;
    margin-left: 30px;

    height: 21px;
  }
}

.menu__home_title {
  margin-right: 25px;
  font-size: 7px;
  margin-left: 10px;
}

@media only screen and (min-width: 375px) {
  .menu__home_title {
    font-size: 8px;
  }
}

@media only screen and (min-width: 768px) {
  .menu__home_title {
    margin-right: 25px;
    font-size: 11px;
    margin-left: 20px;
  }
}

.menu__icon_folder {
  width: 19px;
  height: 12.5px;
  margin-left: 12px;
  margin-right: 10px;
}

@media only screen and (min-width: 768px) {
  .menu__icon_folder {
    width: 32px;

    height: 21px;
    margin-left: 30px;
    margin-right: 20px;
  }
}

.menu__page_aboutUs {
  margin-left: 0px;
  font-size: 7px;
  margin-right: 25px;
}

@media only screen and (min-width: 375px) {
  .menu__page_aboutUs {
    font-size: 8px;
  }
}

@media only screen and (min-width: 768px) {
  .menu__page_aboutUs {
    margin-left: 0px;
    margin-right: 40px;
    font-size: 11px;
  }
}

.menu__page_ito3dsgn {
  margin-left: 0px;
  font-size: 7px;
  margin-right: 20px;
}

@media only screen and (min-width: 375px) {
  .menu__page_ito3dsgn {
    font-size: 8px;
  }
}

@media only screen and (min-width: 768px) {
  .menu__page_ito3dsgn {
    margin-left: 0px;
    margin-right: 35px;
    font-size: 11px;
  }
}

.menu__page_itoGallery {
  font-size: 7px;
  margin-right: 12px;
}

@media only screen and (min-width: 375px) {
  .menu__page_itoGallery {
    font-size: 8px;
    margin-right: 10px;
  }
}

@media only screen and (min-width: 768px) {
  .menu__page_itoGallery {
    margin-left: 0px;
    margin-right: 20px;
    font-size: 11px;
  }
}

.menu__page_itoStudio {
  font-size: 7px;
  margin-right: 16px;
}

@media only screen and (min-width: 375px) {
  .menu__page_itoStudio {
    font-size: 8px;
    margin-right: 15px;
  }
}

@media only screen and (min-width: 768px) {
  .menu__page_itoStudio {
    margin-left: 0px;
    margin-right: 27px;
    font-size: 11px;
  }
}

.menu__pages .menu__wrapper_letsTalk {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 90px;
}

.menu__icon_nextPage {
  height: 8px;
  width: 6px;
  margin-right: 0px;
}

@media only screen and (min-width: 768px) {
  .menu__icon_nextPage {
    height: 13.33px;
    width: 10px;
    margin-right: 0px;
  }
}

.menu__pages .menu___socialMedia {
  display: flex;
  justify-content: center;
  height: 50px;
  margin-top: -25px;
  margin-left: 15px;
}

.menu__pages .menu__socialMedia_icon {
  height: 50px;
  width: 30px;
  margin-top: 40px;
  margin-right: 8px;
}

@media only screen and (min-width: 768px) {
  .menu__pages .menu__socialMedia_icon {
    height: 50px;
    width: 30px;
    margin-top: 40px;
    margin-right: 20px;
  }
}

.menu__icon_twitter {
  width: 16px;
  height: 13px;
}

@media only screen and (min-width: 768px) {
  .menu__icon_twitter {
    width: 23px;
    height: 16.6px;
  }
}

.menu__icon_instagram {
  width: 16.8px;
  height: 16.8px;
}

@media only screen and (min-width: 768px) {
  .menu__icon_instagram {
    width: 23px;
    height: 23px;
  }
}

.menu__icon_frame {
  width: 20px;
  height: 15px;
}

@media only screen and (min-width: 768px) {
  .menu__icon_frame {
    width: 33.3px;
    height: 25px;
  }
}

/* SubMenu */

.submenu,
.submenu__blog {
  width: 112px;
  max-width: 100%;
  height: 100px;
  position: absolute;
  top: -2px;
  right: -117px;
  border: 2px solid #8e8e8e;
  background: #ededed;
  font-size: 10px;
}

@media only screen and (min-width: 375px) {
  .submenu,
  .submenu__blog {
    width: 123px;
    right: -125px;
  }
}

@media only screen and (min-width: 768px) {
  .submenu,
  .submenu__blog {
    width: 200px;
    right: -207px;
    height: 140px;
    top: -3px;
    border: 3px solid #8e8e8e;
  }
}

.submenu__blog {
  height: 60px;
}

@media only screen and (min-width: 768px) {
  .submenu__blog {
    height: 75px;
  }
}

.subMenu__icon {
  width: 20.4px;
  height: 15px;
  margin-right: 11px;
}

@media only screen and (min-width: 768px) {
  .subMenu__icon {
    width: 32px;
    height: 25px;
    margin-right: 15px;
  }
}

.subMenu__title {
  font-size: 7px;
}

@media only screen and (min-width: 375px) {
  .subMenu__title {
    font-size: 8px;
  }
}

@media only screen and (min-width: 768px) {
  .subMenu__title {
    font-size: 11px;
  }
}

.folder {
  position: relative;
}

.subMenu__ito3dsgn,
.subMenu__itoGallery,
.subMenu__itoStudio,
.subMenu__aboutUs {
  width: 200px;
  max-width: 100%;
  height: 100px;
  margin-top: 10px;
}

@media only screen and (min-width: 768px) {
  .subMenu__ito3dsgn,
  .subMenu__itoGallery,
  .subMenu__itoStudio,
  .subMenu__aboutUs {
    margin-top: 20px;
  }
}

.subMenu__itoStudio a,
.subMenu__itoGallery a {
  text-decoration: none;
}

.subMenu__ito3dsgn_art,
.subMenu__itoGallery_NFT,
.subMenu__itoStudio_Rodrigo,
.subMenu__aboutUs_blog {
  padding-left: 10px;
  padding-right: 0px;
  display: flex;
  margin-bottom: 10px;
  height: 20px;
  align-items: center;
  margin-top: 15px;
}

@media only screen and (min-width: 768px) {
  .subMenu__ito3dsgn_art,
  .subMenu__itoGallery_NFT,
  .subMenu__itoStudio_Rodrigo,
  .subMenu__aboutUs_blog {
    padding-left: 30px;
    margin-bottom: 15px;
    height: 30px;
    font-size: 11px;
  }
}

.subMenu__itoGallery_NFT {
  padding-right: 0;
  padding-left: 0;
  justify-content: space-between;
}

.subMenu__itoGallery_NFT .submenu__wrapper_NFT {
  width: 112px;
  max-width: 100%;
  height: 100px;
  position: absolute;
  top: -17px;
  right: -116px;
  border: 2px solid #8e8e8e;
  font-size: 10px;
  background: #ededed;
  display: none;
}

@media only screen and (min-width: 375px) {
  .subMenu__itoGallery_NFT .submenu__wrapper_NFT {
    width: 120px;
    right: -124px;
  }
}

@media only screen and (min-width: 768px) {
  .subMenu__itoGallery_NFT .submenu__wrapper_NFT {
    width: 200px;
    right: -205px;
    height: 140px;
    top: -23px;
    border: 3px solid #8e8e8e;
  }
}

.subMenu__itoGallery_NFT:hover .submenu__wrapper_NFT {
  display: block;
}

.submenu__NFT {
  width: 200px;
  max-width: 100%;
  height: 50px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  margin-bottom: -10px;
}

@media only screen and (min-width: 768px) {
  .submenu__NFT {
    margin-top: 20px;
  }
}

.submenu__NFT:hover {
  background-color: unset;
}

.submenu__NFT_isia {
  width: 21.6px;
  height: 6px;
  margin-right: 15px;
  margin-top: -20px;
}

@media only screen and (min-width: 768px) {
  .submenu__NFT_isia {
    width: 36px;
    height: 10px;
    margin-right: 15px;
    margin-top: -20px;
  }
}

.submenu__NFT_isia2 {
  width: 15px;
  height: 11px;
  margin-top: -20px;
}

@media only screen and (min-width: 768px) {
  .submenu__NFT_isia2 {
    width: 25px;
    height: 18px;
    margin-top: -20px;
  }
}

.submenu__NFT_vector {
  width: 13.2px;
  height: 13.8px;
  margin-right: 15px;
  margin-bottom: 55px;
}

@media only screen and (min-width: 768px) {
  .submenu__NFT_vector {
    width: 22px;
    height: 23px;
    margin-right: 15px;
    margin-bottom: 55px;
  }
}

.submenu__NFT_kosmovisiones {
  width: 47px;
  height: 11px;
  margin-bottom: 55px;
}

@media only screen and (min-width: 768px) {
  .submenu__NFT_kosmovisiones {
    width: 79px;
    height: 18px;
    margin-bottom: 55px;
  }
}

.subMenu__ito3dsgn_branding,
.subMenu__itoGallery_events,
.subMenu__itoStudio_Andres {
  padding-left: 10px;
  padding-right: 0px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  height: 20px;
}

@media only screen and (min-width: 768px) {
  .subMenu__ito3dsgn_branding,
  .subMenu__itoGallery_events,
  .subMenu__itoStudio_Andres {
    padding-left: 30px;
    height: 30px;
  }
}

.subMenu__itoGallery_events {
  padding-right: 0;
  padding-left: 0;
}

.subMenu__icon_gallery {
  margin-left: 15px;
  width: 20.4px;
  height: 15px;
}

@media only screen and (min-width: 768px) {
  .subMenu__icon_gallery {
    margin-left: 30px;
    width: 32px;
    height: 25px;
  }
}
