.Home {
  width: 400vw;
  height: 100vh;
  display: flex;
  overflow: hidden;
  z-index: 1;
}

.Home__child {
  width: 100vw;
  height: 100%;
  background: transparent;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
